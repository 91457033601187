import {$dom} from '../../../helpers/dom';
import gsap from "gsap";
import variables from '../../../variables';
import SmoothScrollbar from 'smooth-scrollbar';

const {exist, get} = $dom;
let scrollbar;

export default function setPersonalView(
  viewId = 'js-personal-view',
  navSelector = '.js-personal-profiles-navigation'
) {

  const view = get('#'+viewId, this.rootEl);
  let navigation;

  gsap.set(view, {clearProps: 'height'});

  let disabledSpace = variables.dom.header.offsetHeight + variables.dom.player.offsetHeight;

  if (exist(navSelector)) {
    navigation = get(navSelector, this.rootEl);

    disabledSpace += navigation.offsetHeight;
  }

  gsap.set(view, {height: variables.windowHeight - disabledSpace + 'px'});

  if (scrollbar instanceof SmoothScrollbar) {
    scrollbar.update()
  } else {
    scrollbar = new SmoothScrollbar(view, variables.scrollbarDefaults);
  }

}