import {$dom} from '../../../../helpers/dom';

const {createElement, text, addClass} = $dom;

export default function createLoadMoreBtn(
  classes = '',
  label = 'Загрузить ещё'
) {

  return text(
    addClass(
      createElement('button', {
        type: 'button',
        class: 'button button--default button--bordered waves-light button--waves'
      }),
      classes
    ),
    label
  )
}