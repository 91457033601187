import Page from '../../сlasses/Page';
import variables from '../../../variables';
import {$dom} from '../../../helpers/dom';
import sortingPanel from '../../components/sorting';
import {getData, objHasProp} from '../../../helpers/_utilities';
import createTracksList from '../catalog/templates/createTracksList';
import Player from '../../сlasses/Player';
import PlaybackExtractor from '../../сlasses/PlaybackExtractor';
import {trackListCreateObserver} from '../../observers';
import playerProgressSaver from '../utils/playerProgressSaver';
import prepareData from '../utils/prepareData';
import toggleTrackCard from '../catalog/toggleTrackCard';
import setPersonalView from '../utils/setPersonalView';

const personalPage = new Page({
  onInit() {

    const {
      dataset
    } = this.rootEl;

    if (dataset && objHasProp(dataset, 'currentPage')) {

      const {get, html, append} = $dom;
      const toggleTrackCards = toggleTrackCard();

      const pagesSortingPanels = sortingPanel({
        targets: [
          '.js-personal-profiles--select',
          '.js-personal-history--sorting',
          '.js-personal-favorite--sorting',
          '.js-personal-subscription--sorting'
        ]
      });

      this.musicPlayer = new Player({
        name: 'historyMusicPlayer',
        rootElementId: 'js-page-player'
      });

      this.playbackExtractor = new PlaybackExtractor(variables.xhrRootUrl);

      this.musicPlayer.progressSaver = playerProgressSaver;
      this.playbackExtractor.playerProgressSaver = playerProgressSaver;

      const
        tracksOutputSelector = '.js-personal-tracks-output',
        renderTrackList = playerType => {
          getData(variables.xhrRootUrl+'getTracks')
            .then(data => {
              const trackList = createTracksList(
                data,
                `js-player-template--extended--${playerType}`,
                [
                  {
                    fromStoreKey: 'albumPoster',
                    fromMarkupKey: 'imageSrc'
                  },
                  {
                    fromStoreKey: 'name',
                    fromMarkupKey: 'title'
                  }
                ],
                {
                  printIndex: true
                }
              );

              const output = get(tracksOutputSelector);

              html(output, '');
              append(output, trackList.template);

              //trackListCreateObserver.dispatch(trackList.players);



              this.imagesLazyLoad.instance.update()
            })
            .finally(() => {
              toggleTrackCards.init();

              sortingPanel({
                targets: ['.js-page-player-song-download-select']
              }).create();
            });
        }
      ;

      this.musicPlayer.init();
      //this.playbackExtractor.init(this);

      switch (dataset.currentPage) {
        case 'history':
          renderTrackList('default');
          break;

        case 'favorite':
          renderTrackList('favorite');
          break;

        case 'subscription':
          renderTrackList('download');
          break;

      }

      pagesSortingPanels.create();
    }
    toggleTrackCard().init()

    sortingPanel({
      targets: ['.js-page-player-song-download-select']
    }).create();
  },
  onDestroy() {

  },
  name: 'personal-profiles',
  rootElementId: 'js-personal-page',
  resizeDependentMethods: [
    setPersonalView
  ]
});

export default personalPage;