const variables = {
	$EXTERNAL_API_NAME: '$API',
	gsapDefaultDuration: .25,
  carouselDefaultDuration: 600,
	resizeDebounce: 100,
	debugLogs: true,
	debugLogsDisabledNotify: true,
	customEventNames: {
		resize: 'resize-done',
		animateEnd: 'animate-end',
		modal: {
			open: 'modal-open',
			opened: 'modal-opened',
			close: 'modal-close',
			closed: 'modal-close-end'
		},
    catalog: {
		  filterChanged: 'catalog-filter-changed'
    }
	},
  classNames: {
    disabled: 'is-disabled',
    active: 'is-active',
    lazyLoading: 'is-loading',
    lazyPreloader: 'lazy__preloader',
    lazyImage: 'lazy__image',
    lazyLoaded: 'is-loaded',
    blurred: 'is-blurred',
    error: 'is-error',
    batchTarget: 'js-scroll-batch-element',
    keyboardArrowsPreventDefault: 'js-keyboard-x-arrow-prevent-default',
    catalogPage: {
      filter: 'js-catalog-page-filter',
      main: 'js-catalog-main',
      content: 'js-catalog-content',
      header: 'js-catalog-header',
      trackList: 'card__detailed-tracks',
      cardsOutput: 'js-cards-output',
      col: 'catalog__content-col'
    },
    select: {
      containerOuter: 'select',
      containerInner: 'select__inner',
      input: 'select__input',
      inputCloned: 'select__input--cloned',
      list: 'select__list',
      listItems: 'select__list--multiple',
      listSingle: 'select__list--single',
      listDropdown: 'select__list--dropdown',
      item: 'select__item',
      itemSelectable: 'select__item--selectable',
      itemDisabled: 'select__item--disabled',
      itemChoice: 'select__item--choice',
      placeholder: 'select__placeholder',
      group: 'select__group',
      groupHeading: 'select__heading',
      button: 'select__button',
      activeState: 'is-active',
      focusState: 'is-focused',
      openState: 'is-open',
      disabledState: 'is-disabled',
      highlightedState: 'is-highlighted',
      selectedState: 'is-selected',
      flippedState: 'is-flipped',
      loadingState: 'is-loading',
      noResults: 'has-no-results',
      noChoices: 'has-no-select'
    }
  },
  dataAttributes: {
	  filterElId: 'data-filter-id',
    playTarget: 'data-play-target-id'
  },
	get windowWidth() {
		return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
	},
	get windowHeight() {
		return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
	},
	scrollbarWidth:	window.innerWidth - document.documentElement.clientWidth,
	breakpoints: {
		sm: 576,
		md: 768,
		lg: 992,
		xl: 1200,
	},
  dom: {
	  header: document.getElementById('js-page-header'),
	  footer: document.getElementById('js-page-footer'),
    player: document.getElementById('js-page-player'),
    loader: document.getElementById('js-loader')
  },
  rootUrl: 'http://zvukobaza.itfactory.site',
  xhrRootUrl: 'http://zvukobaza.itfactory.site/api/v1/',
  xhrRoutes: {
	  collections: 'getCollections',
    albums: 'getAlbums',
    tracks: 'getTracks',
    playlists: 'getPlaylists'
  },
  savedDataStorageKey: 'mock-data',
  scrollbarDefaults: {
    damping: .08,
    alwaysShowTracks: true
  },
  storageKeys: {
	  filter: 'CURRENT_CATALOG_FILTER',
    filterFormData: 'CURRENT_CATALOG_FORM_DATA'
  }
};

variables.defaultSwiperLazyOptions = {
  loadPrevNext: false,
  preloaderClass: variables.classNames.lazyPreloader,
  elementClass: variables.classNames.lazyImage,
  loadedClass: variables.classNames.lazyLoaded,
  loadingClass: variables.classNames.lazyLoading
};

export default variables;
