import {$dom} from '../../../../helpers/dom';
import {stringToHtmlElement} from '../../../../helpers/_utilities';

const {createElement, append} = $dom;

export default function createBackButton(
  href,
  classes = '',
  text = 'Назад'
) {

  const
    icon = stringToHtmlElement('<svg class="svg-icon"><use xlink:href="img/sprite.svg#back-arrow"></use></svg>'),
    span = stringToHtmlElement(`<span></span>`)
  ;

  const a = createElement('a', {
    href,
    dataCatalogRouterLink: '',
    class: `text--small card__back js-catalog-back${classes && ' ' + classes}`
  });

  append(a, icon);
  append(a, span);

  span.innerText = text;

  return a
}