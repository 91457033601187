import Model from './Model';
import {Howl, Howler} from 'howler';
import {$data} from '../../helpers/data';
import {$events} from '../../helpers/events';
import {playbackObserver, trackListCreateObserver} from '../observers';
import {$dom} from '../../helpers/dom';
import {
  convertSecondsToPlayerFormat,
  isElement,
  isEqualArrays,
  sleep,
  warn
} from '../../helpers/_utilities';
import is from 'is_js';
import gsap from 'gsap';
import Draggable from 'gsap/Draggable'
import {$style} from '../../helpers/style';
import variables from '../../variables';

gsap.registerPlugin(Draggable);

const {
  get: domGet,
  addClass,
  removeClass,
  hasClass,
  attr,
  html,
  createElement,
  append,
  getParent,
  exist,
  text,
  getAll
} = $dom;


export default class Player extends Model {
  constructor(options) {
    super(options);

    this.defaults = {
      playButtonSelector: '.js-page-player-play',
      prevNextSelector: '.js-page-player-track',

      radialProgressSelector: '.js-page-player-radial-progress',
      radialProgressBarSelector: '.js-page-player-radial-progress-bar',

      songNameOutputSelector: '.js-page-player-song-name',
      songTagsOutputSelector: '.js-page-player-song-tags',
      songDurationCurrentSelector: '.js-page-player-song-duration-current',
      songDurationTotalSelector: '.js-page-player-song-duration-total',

      volumeButtonSelector: '.js-page-player-volume',
      volumeTrackSelector: '.js-page-player-song-volume-track',
      volumeBarSelector: '.js-page-player-song-volume-bar',
      volumeProgressSelector: '.js-page-player-song-volume-progress',

      oscillogramSelector: '.js-page-player-song-oscillogram',
      oscillogramCanvasBarSelector: '.js-page-player-song-oscillogram-bar',
      oscillogramCanvasProgressSelector: '.js-page-player-song-oscillogram-progress',
      defaultProgressBarSelector: '.js-page-player-song-progress',

      progressAlternativeSelector: '.js-page-player-song-progress-alternative',
      progressOscillogramSelector: '.js-page-player-song-progress-oscillogram',

      catalogContentSelector: '.'+variables.classNames.catalogPage.content,

      playerOnPageSelector: '.js-player-on-page',
      playerOnPagePlayButtonSelector: '.js-page-player-track-play',

      audioContextResumeModalId: 'modal--audio-context',
      audioContextResumeButtonSelector: '.js-audio-context-resume',
      audioContextPauseButtonSelector: '.js-audio-context-pause',

      loadingClass: 'is-loading',
      playingClass: 'is-playing',
      disabledClass: 'is-disabled',
      pausedClass: 'is-paused',
      defaultVolume: .5,
      volumeStorageKey: 'LAST_PLAYER_VOLUME',
      progressStorageKey: 'last-player-progress',
      oscillogramBarColor: '#05BC7A',
      oscillogramProgressColor: '#ffffff',
      pagePlayerOscillogramBarColor: '#E1E1E1',
      pagePlayerOscillogramProgressColor: '#10DB92',
      recordProgressInterval: 1000
    };

    this.defaults['prevButtonSelector'] = `${this.defaults.prevNextSelector}[data-direction="prev"]`;
    this.defaults['nextButtonSelector'] = `${this.defaults.prevNextSelector}[data-direction="next"]`;

    this.options = $data.deepAssign(this.defaults, options);
    this.playlist = [];
    this.howlPlayer = null;
    this.currentTrackIndex = null;
    this.volumeDraggableInstance = null;
    this.currentVolume = this.options.defaultVolume;
    this.currentProgressElement = null;
    this.progressStorage = this.recordProgressStorage();
    this.paused = null;
    this.rewinedAfterPageLoad = null;
    this.playersOnPage = [];
    this.prevSwitchingPercent = 10;

    this.processData = this.processData.bind(this);
    this.togglePlay = this.togglePlay.bind(this);
    this.skip = this.skip.bind(this);
    this.step = this.step.bind(this);
    this.changeVolumeByClick = this.changeVolumeByClick.bind(this);
    this.drawOscillogram = this.drawOscillogram.bind(this);
    this.seekByOscillogramClick = this.seekByOscillogramClick.bind(this);
    this.setProgressView = this.setProgressView.bind(this);
    this.playersOnPageRendered = this.playersOnPageRendered.bind(this);
    this.checkCurrentPagePlayer = this.checkCurrentPagePlayer.bind(this);
    this.handlePlayersOnPageClick = this.handlePlayersOnPageClick.bind(this);
    this.handleKeyboard = this.handleKeyboard.bind(this);
  }

  _loop(array, cb) {
    for (let i = 0; i < array.length; i++) cb(array[i], i)
  }

  _clearCanvas(arr) {
    this._loop(arr, canvas => {
      let context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height)
    });
  }

  get currentTrack() {
    return this.playlist[this.currentTrackIndex]
  }

  get playerReady() {
    return this.howlPlayer instanceof Howl
  }

  get currentPagePlayer () {

    return isEqualArrays(this.playlist, this.playersOnPage.map(p => p.track))
      ?
      this.playersOnPage[this.currentTrackIndex]
      :
      null
  }

  setPlayButtonView(view) {

    switch (view) {
      case 'disabled':

        removeClass(this.controlPlay, [
          this.options.loadingClass,
          this.options.readyClass,
          this.options.pausedClass
        ]);

        addClass(this.controlPlay, this.options.disabledClass);
        break;
      case 'loading':
        removeClass(this.controlPlay, [
          this.options.disabledClass,
          this.options.readyClass,
          this.options.pausedClass
        ]);

        addClass(this.controlPlay, this.options.loadingClass);
        break;
      case 'playing':
        removeClass(this.controlPlay, [
          this.options.disabledClass,
          this.options.loadingClass,
          this.options.pausedClass
        ]);

        addClass(this.controlPlay, this.options.playingClass);
        break;
      case 'paused':
        removeClass(this.controlPlay, [
          this.options.disabledClass,
          this.options.loadingClass,
          this.options.playingClass
        ]);

        addClass(this.controlPlay, this.options.pausedClass);
        break;

      case 'default':
        removeClass(this.controlPlay, [
          this.options.disabledClass,
          this.options.loadingClass,
          this.options.pausedClass,
          this.options.playingClass
        ]);
        break;
    }
  }

  setTrackControlsView(view) {
    switch (view) {
      case 'disablePrev':
        this.controlPrev.disabled = true;
        break;

      case 'disableNext':
        this.controlNext.disabled = true;
        break;

      case 'enablePrev':
        this.controlPrev.disabled = false;
        break;

      case 'enableNext':
        this.controlNext.disabled = false;
        break;

      case 'disableAll':
        this.controlPrev.disabled = true;
        this.controlNext.disabled = true;
        break;

      case 'enableAll':
        this.controlPrev.disabled = false;
        this.controlNext.disabled = false;
        break;
    }
  }

  setProgressView() {
    const
      {oscillogram} = this.currentTrack,
      {oscillogramCanvasBar, oscillogramCanvasProgress} = this
    ;



    if (oscillogram) {
      this.drawOscillogram(
        oscillogram,
        oscillogramCanvasBar,
        this.options.oscillogramBarColor,
      );

      this.drawOscillogram(
        oscillogram,
        oscillogramCanvasProgress,
        this.options.oscillogramProgressColor
      );

      gsap.set(this.progressOscillogram, {display: 'block'});
      gsap.set(this.progressAlternative, {display: 'none'});

      this.currentProgressElement = this.oscillogramProgress;
    } else {
      this._clearCanvas([
        oscillogramCanvasBar,
        oscillogramCanvasProgress
      ]);

      gsap.set(this.progressAlternative, {display: 'block'});
      gsap.set(this.progressOscillogram, {display: 'none'});

      this.currentProgressElement = this.defaultProgressBar;
    }

  }

  setPagePlayerView(player, view) {
    const btn = domGet(this.options.playerOnPagePlayButtonSelector, player.card);

    switch (view) {
      case 'playing':
        removeClass([btn, player.card], this.options.pausedClass);
        addClass([btn, player.card], this.options.playingClass);
        break;

      case 'paused':
        removeClass([btn, player.card], this.options.playingClass);
        addClass([btn, player.card], this.options.pausedClass);
        break;
    }
  }

  togglePlay(event) {
    const btn = event.target;

    if (hasClass(btn, this.options.disabledClass)) return;

    hasClass(btn, this.options.playingClass)
      ?
      this.howlPlayer.pause()
      :
      this.howlPlayer.play()
    ;
  }

  step() {

    const seek = this.howlPlayer.seek() || 0;
    const progress = parseFloat((seek / this.howlPlayer.duration() * 100).toFixed(2)) / 100;
    const dashoffset = this.radialProgressCircumFerence * (1 - progress);

    if (dashoffset > 0) {
      gsap.set(this.radialProgressBar, {
        attr: {
          'stroke-dashoffset': dashoffset > 0 ? dashoffset : 0
        }
      });
    }

    const
      currentPagePlayerExist = is.object(this.currentPagePlayer),
      progressPercentage = progress * 100 + '%',
      currentDurationOutputTargets = [],
      progressOutputTargets = []
    ;

    currentDurationOutputTargets.push(this.songDurationCurrentOutput);
    progressOutputTargets.push(this.currentProgressElement);

    if (currentPagePlayerExist && exist([
      this.options.songDurationCurrentSelector,
      this.options.defaultProgressBarSelector
    ], this.currentPagePlayer.card)) {
      const searchInCard = selector => domGet(selector, this.currentPagePlayer.card);

      currentDurationOutputTargets.push(searchInCard(this.options.songDurationCurrentSelector));

      progressOutputTargets.push(
        getAll(this.options.defaultProgressBarSelector, this.currentPagePlayer.card)
          .filter(p => !(p.offsetParent === null))[0]
      );
    }

    gsap.set(progressOutputTargets, {width: progressPercentage});
    html(currentDurationOutputTargets, convertSecondsToPlayerFormat(seek));

    if (this.howlPlayer.playing()) requestAnimationFrame(this.step)
  }

  printTags() {
    html(this.songTagsOutput, '');

    if (is.array(this.currentTrack.tags) && this.currentTrack.tags.length > 0) {
      gsap.set(this.songTagsOutput, {autoAlpha: 1});
      this._loop(this.currentTrack.tags, tag => {
        if (Boolean(tag)) append(this.songTagsOutput, html(createElement('li'), tag));
      })
    } else {
      gsap.set(this.songTagsOutput, {autoAlpha: 0});
    }
  }

  printData() {
    html(this.songNameOutput, this.currentTrack.name);
    this.printTags();
  }

  processData(data) {
    this.playlist = data.tracks;

    if (this.playlist.length > 0) {
      this.play(data.startWith)
    } else {
      warn(`Player playlist is empty`, 'Player processData method')
    }
  }

  drawOscillogram(arr, canvas, color) {
    console.log('drawOscillogram')

    const
      parent = getParent(canvas, this.options.oscillogramSelector),
      context = canvas.getContext('2d'),
      height = parent.offsetHeight,
      width = parent.offsetWidth
    ;

    canvas.height = height;
    canvas.width = width;

    this._loop(arr, (num, i) => {
      // num = parseInt(num / 750);

      const
        columnWidth = 1,
        x = i * (columnWidth * 2),
        halfHeight = height / 2
      ;

      let columnHeight = (num * (canvas.height / 100) * -1) / 2;

      context.fillStyle = color;
      context.fillRect(x, halfHeight + columnWidth, columnWidth, columnHeight);
      context.fillRect(x, halfHeight - columnWidth, columnWidth, Math.abs(columnHeight));
    });

    return context
  }

  seek(percentage) {
    this.howlPlayer.seek(this.howlPlayer.duration() * percentage / 100)
  }

  seekByOscillogramClick(event) {

    const
      oscillogram = event.target.closest(this.options.oscillogramSelector),
      o_offsetLeft = $style.offset(oscillogram).left,
      o_width = oscillogram.offsetWidth
    ;

    const playerOnPage = event.target.closest(this.options.playerOnPageSelector);

    if (
      playerOnPage
      &&
      is.object(this.currentPagePlayer)
      &&
      playerOnPage !== this.currentPagePlayer.card) return;

    let pageX = event.pageX ? event.pageX : event.originalEvent.changedTouches[0].pageX;

    if (pageX >= o_offsetLeft) {
      const diff = pageX - o_offsetLeft;

      if (this.howlPlayer instanceof Howl && this.howlPlayer.playing()) {
        this.seek(diff / o_width * 100);
      }
    }
  }

  play(index) {
    const $player = this;


    if (is.object(this.currentPagePlayer) && exist([
      this.options.songDurationCurrentSelector,
      this.options.defaultProgressBarSelector
    ], this.currentPagePlayer.card)) {
      text(domGet(this.options.songDurationCurrentSelector, this.currentPagePlayer.card), '0:00');
      gsap.set(getAll(this.options.defaultProgressBarSelector, this.currentPagePlayer.card), {
        width: 0
      });
    }

    $player.currentTrackIndex = index;


    let currentSrc = variables.rootUrl + $player.currentTrack.src;

    if ($player.playerReady) {
      $player.howlPlayer.unload();
      $player.howlPlayer = null;
    }

    if (currentSrc) {
      const currentTrack = $player.playlist[index];

      $player.howlPlayer = new Howl({
        src: variables.rootUrl + currentTrack.src,
        html5: true,
        usingWebAudio: true,
        ctx: true,
        onplay() {
          // console.log('howlPlayer onplay');
          $player.setPlayButtonView('playing');
          requestAnimationFrame($player.step);

          const totalDuration = convertSecondsToPlayerFormat($player.howlPlayer.duration());
          html($player.songDurationTotalOutput, totalDuration);

          $player.progressStorage.write();
          $player.paused = false;
          $player.progressSaver.save({paused: $player.paused});


          sleep(0)
            .then($player.checkCurrentPagePlayer)
        },
        onload() {
          // console.log('howlPlayer onload');
          $player.setProgressView();

          if (is.null($player.rewinedAfterPageLoad)) {
            const {progress} = $player.progressSaver.get();
            $player.seek(progress);
            $player.rewinedAfterPageLoad = true;
          }

          $player.checkCurrentPagePlayer();
        },
        onend() {
          // console.log('howlPlayer onend');

          if ($player.playlist[index + 1]) {
            $player.play(index + 1)
          } else {
            if ($player.playlist.length > 1) {
              $player.play(0)
            }
          }

          $player.setPlayButtonView('paused');
          $player.checkCurrentPagePlayer();
        },
        onpause() {
          // console.log('howlPlayer onpause');
          $player.setPlayButtonView('paused');
          $player.progressStorage.clear();
          $player.paused = true;
          $player.progressSaver.save({paused: $player.paused});

          $player.checkCurrentPagePlayer();
        },
        onstop() {
          // console.log('howlPlayer onstop');
          $player.progressStorage.clear();
          $player.paused = true;
          $player.progressSaver.save({paused: $player.paused});
          $player.checkCurrentPagePlayer();
        },
        onseek() {
          // console.log('howlPlayer onseek');
          requestAnimationFrame($player.step);
        },
        onloaderror() {
          // console.log('howlPlayer onloaderror');
          $player.volumeButton.disabled = true;
        }
      });


      $player.howlPlayer.volume($player.currentVolume);
      $player.howlPlayer.play();



      const trackExist = direction => {
        let i;

        switch (direction) {
          case 'prev':
            i = index - 1;
            break;

          case 'next':
            i = index + 1;
            break;
        }

        return Boolean($player.playlist[i])
      };
      $player.setTrackControlsView(trackExist('prev') ? 'enablePrev' : 'disablePrev');
      $player.setTrackControlsView(trackExist('next') ? 'enableNext' : 'disableNext');

      $player.printData();

      $player.volumeButton.disabled = false;

      const
        resume = () => {
          Howler.ctx.resume().then(() => {
            $player.howlPlayer.play();
          })
        },
        pause = () => {
          Howler.ctx.resume().then(() => {
            $player.howlPlayer.pause();
          })
        };

      if (Howler.ctx.state === 'suspended') {
        window[variables.$EXTERNAL_API_NAME].ModalController.open($player.options.audioContextResumeModalId);
        $player.setPlayButtonView('disabled');

        $events.delegate
          .on('click tap', $player.options.audioContextResumeButtonSelector, resume)
          .on('click tap', $player.options.audioContextPauseButtonSelector, pause)
      } else {
        if ($player.progressSaver.get().paused) $player.howlPlayer.pause();
      }

    } else {
      warn('Something went wrong with track source', 'play method, Player class');
      $player.volumeButton.disabled = true;
    }


  }

  skip(arg) {
    let direction;

    if (is.string(arg)) {
      direction = arg
    } else {
      direction = arg.target.dataset.direction
    }

    let index;


    const getLastPlayTarget = () => {
      if (is.array(this.playersOnPage) && this.playersOnPage.length > 0) {
        let i;
        switch (direction) {
          case 'prev':
            i = this.currentTrackIndex - 1;
            break;
          case 'next':
            i = this.currentTrackIndex + 1;
            break;
        }

        if (is.object(this.playersOnPage[i]))
          return this.playersOnPage[i].track.playTargetId;
      }
    };

    switch (direction) {
      case 'prev':

        const currentTrackProgress = this.howlPlayer.seek() / this.howlPlayer.duration() * 100;

        if (currentTrackProgress <= this.prevSwitchingPercent) {
          index = this.currentTrackIndex - 1;
          if (index < 0) index = this.playlist.length - 1;
        } else {
          index = this.currentTrackIndex
        }

        break;

      case 'next':
        index = this.currentTrackIndex + 1;
        if (index >= this.playlist.length) index = 0;
        break;
    }

    this.progressSaver.save({lastPlayTarget: getLastPlayTarget()});
    this.progressStorage.save();
    this.skipTo(index);
  }

  skipTo(index) {
    this.play(index)
  }

  setVolume(value) {
    this.currentVolume = value;

    localStorage.setItem(
      this.options.volumeStorageKey,
      this.currentVolume
    );

    if (this.playerReady) this.howlPlayer.volume(this.currentVolume)
  }

  setVolumeProgress(value) {
    gsap.set(this.volumeProgress, {height: value * 100 + '%'});
  }

  updateVolumeBarPosition(value) {
    this.currentVolume = value;

    gsap.set(this.volumeBar, {y: - this.currentVolume * 100 + 'px'});

    this.setVolumeProgress(value);

    this.volumeDraggableInstance.update();
  }

  volumeDraggable(action) {
    const $player = this;

    switch (action) {
      case 'init':

        let initVolume = (() => {
            let storageValue = localStorage.getItem(this.options.volumeStorageKey);

            if (is.null(storageValue)) return this.options.defaultVolume;

            if (is.string(storageValue)) return +storageValue;
          })()
        ;

        if (this.volumeDraggableInstance instanceof Draggable) {
          this.volumeDraggableInstance.enable();

        } else {
          this.volumeDraggableInstance = Draggable.create(this.volumeBar, {
            type: 'y',
            bounds: this.volumeTrack,
            cursor: 'pointer',
            onDrag() {
              let val = Math.abs(this.y) / 100;
              $player.setVolume(val);
              $player.setVolumeProgress(val)
            }
          })[0];
        }


        this.updateVolumeBarPosition(initVolume);
        this.setVolume(initVolume);

        break;

      case 'disable':

        if (this.volumeDraggableInstance instanceof Draggable) {
          this.volumeDraggableInstance.disable();
        }
        break;
    }
  }

  changeVolumeByClick(event) {
    const track = event.target.closest(this.options.volumeTrackSelector);
    let value = 100 - (event.clientY - $style.offset(track).top - this.volumeBar.offsetWidth / 2);

    if (value > 100) value = 100;
    if (value < 0) value = 0;

    value = value / 100;

    this.updateVolumeBarPosition(value);
    this.setVolume(value)
  }

  recordProgressStorage() {

    let interval;

    const
      save = () => {
        let result = {
          trackIndex: this.playlist.indexOf(this.currentTrack),
          trackId: this.currentTrack.id,
          progress: this.howlPlayer.seek() * 100 / this.howlPlayer.duration()
        };

        this.progressSaver.save(result);
      },
      write = () => {
        interval = setInterval(() => {
          if (this.playerReady && this.howlPlayer.playing()) {
            save()
          }
        }, this.options.recordProgressInterval)
      },
      clear = () => {
        clearInterval(interval);
      }
    ;

    return {
      write, clear, save
    }
  }

  checkCurrentPagePlayer() {

    const {currentPagePlayer} = this;

    if (isEqualArrays(this.playlist, this.playersOnPage.map(p => p.track))) {
      this._loop(this.playersOnPage, player => {
        if (player !== currentPagePlayer) {
          this.setPagePlayerView(player, 'paused');

          $style.remove(player.card, 'border')
        } else {
          this.setPagePlayerView(player, this.howlPlayer.playing() ? 'playing' : 'paused');

          $style.set(player.card, 'border', '1px solid green');
        }
      });
    }
  }

  playersOnPageRendered({players, updatePlaylist}) {

    this.playersOnPage = players;

    this._loop(this.playersOnPage, player => {
      this.setPagePlayerView(player, 'paused');

      if (exist([
        this.options.oscillogramCanvasBarSelector,
        this.options.oscillogramCanvasProgressSelector
      ], player.card)) {
        const
          oscillogramCanvasBar = domGet(this.options.oscillogramCanvasBarSelector, player.card),
          oscillogramCanvasProgress = domGet(this.options.oscillogramCanvasProgressSelector, player.card),
          progressOscillogram = domGet(this.options.progressOscillogramSelector, player.card),
          progressAlternative = domGet(this.options.progressAlternativeSelector, player.card)
        ;

        if (player.track.oscillogram) {

          this.drawOscillogram(
            player.track.oscillogram,
            oscillogramCanvasBar,
            this.options.pagePlayerOscillogramBarColor
          );

          this.drawOscillogram(
            player.track.oscillogram,
            oscillogramCanvasProgress,
            this.options.pagePlayerOscillogramProgressColor
          );

          gsap.set(progressOscillogram, {display: 'block'});
          gsap.set(progressAlternative, {display: 'none'});
        } else {
          gsap.set(progressOscillogram, {display: 'none'});
          gsap.set(progressAlternative, {display: 'block'});
        }
      }
    });

    if (updatePlaylist) {
      this.playlist = [...this.playlist, ...players.map(player => player.track)]
    }


    this.checkCurrentPagePlayer()
  }

  handlePlayersOnPageClick(event) {
    const playButton = event.target.closest(this.options.playerOnPagePlayButtonSelector);

    if (isElement(playButton)) {
      if (hasClass(playButton, this.options.pausedClass)) {

        this.progressSaver.save({paused: false});

        if(this.playerReady) this.howlPlayer.play()

      } else {
        this.howlPlayer.pause()
      }
    }

  }

  handleKeyboard(event) {

    let
      isPrevArrow = event.keyCode === 37,
      isNextArrow = event.keyCode === 39
    ;

    if (!(event.target.closest('.'+variables.classNames.keyboardArrowsPreventDefault))) {
      if (this.playerReady && this.howlPlayer.playing()) {
        if (isPrevArrow) this.skip('prev');
        if (isNextArrow) this.skip('next')
      }
    }

  }

  listeners(action) {
    switch (action) {
      case 'add':

        playbackObserver.subscribe(this.processData);
        trackListCreateObserver.subscribe(this.playersOnPageRendered);

        $events.delegate
          .on('click tap', this.options.playButtonSelector, this.togglePlay)
          .on('click tap', this.options.prevNextSelector, this.skip)
          .on('click tap', this.options.volumeTrackSelector, this.changeVolumeByClick)
          .on('click tap', this.options.oscillogramSelector, this.seekByOscillogramClick)
          .on('click tap', this.options.playerOnPagePlayButtonSelector, this.handlePlayersOnPageClick)
          .on('keydown', document, this.handleKeyboard)
        ;


        break;

      case 'remove':

        playbackObserver.unsubscribe(this.processData);
        trackListCreateObserver.unsubscribe(this.playersOnPageRendered);

        $events.delegate
          .off('click tap', this.options.playButtonSelector, this.togglePlay)
          .off('click tap', this.options.prevNextSelector, this.skip)
          .off('click tap', this.options.volumeTrackSelector, this.changeVolumeByClick)
          .off('click tap', this.options.oscillogramSelector, this.seekByOscillogramClick)
          .off('click tap', this.options.catalogContentSelector, this.handlePlayersOnPageClick)
          .off('click tap', document, this.handleKeyboard)
        ;

        break;
    }
  }

  init() {

    this.controlPlay = domGet(this.options.playButtonSelector, this.rootEl);
    this.controlPrev = domGet(this.options.prevButtonSelector, this.rootEl);
    this.controlNext = domGet(this.options.nextButtonSelector, this.rootEl);

    this.radialProgressElement = domGet(this.options.radialProgressSelector, this.rootEl);
    this.radialProgressBar = domGet(this.options.radialProgressBarSelector, this.radialProgressElement);

    this.songNameOutput = domGet(this.options.songNameOutputSelector, this.rootEl);
    this.songTagsOutput = domGet(this.options.songTagsOutputSelector, this.rootEl);
    this.songDurationCurrentOutput = domGet(this.options.songDurationCurrentSelector, this.rootEl);
    this.songDurationTotalOutput = domGet(this.options.songDurationTotalSelector, this.rootEl);

    this.volumeButton = domGet(this.options.volumeButtonSelector, this.rootEl);
    this.volumeTrack = domGet(this.options.volumeTrackSelector, this.rootEl);
    this.volumeProgress = domGet(this.options.volumeProgressSelector, this.rootEl);
    this.volumeBar = domGet(this.options.volumeBarSelector, this.volumeTrack);

    this.oscillogram = domGet(this.options.oscillogramSelector, this.rootEl);

    this.oscillogramCanvasBar = domGet(this.options.oscillogramCanvasBarSelector, this.oscillogram);
    this.oscillogramCanvasProgress = domGet(this.options.oscillogramCanvasProgressSelector, this.oscillogram);
    this.oscillogramProgress = getParent(this.oscillogramCanvasProgress);
    this.defaultProgressBar = domGet(this.options.defaultProgressBarSelector, this.oscillogram);

    this.progressAlternative = domGet(this.options.progressAlternativeSelector, this.rootEl);
    this.progressOscillogram = domGet(this.options.progressOscillogramSelector, this.rootEl);

    this.radialProgressRadius = +attr(this.radialProgressBar, 'r');
    this.radialProgressCircumFerence = 2 * Math.PI * this.radialProgressRadius;

    this.listeners('add');
    this.setPlayButtonView('disabled');
    this.setTrackControlsView('disableAll');
    this.volumeDraggable('init');

    super.init();
  }

  destroy() {
    this.listeners('remove');
    this.setPlayButtonView('default');
    this.setTrackControlsView('enableAll');
    this.volumeDraggable('disable');

    this.progressStorage.clear();

    this.controlPlay = null;
    this.controlPrev = null;
    this.controlNext = null;
    this.radialProgressElement = null;
    this.radialProgressBar = null;
    this.songNameOutput = null;
    this.songTagsOutput = null;
    this.songDurationCurrentOutput = null;
    this.songDurationTotalOutput = null;
    this.volumeButton = null;
    this.volumeTrack = null;
    this.volumeBar = null;
    this.volumeProgress = null;
    this.oscillogram = null;
    this.oscillogramCanvasBar = null;
    this.oscillogramCanvasProgress = null;

    this.radialProgressRadius = 0;
    this.radialProgressCircumFerence = 0;

    if (this.playerReady) this.howlPlayer.stop();

    super.destroy();
  }
}
