import {$dom} from '../../helpers/dom';
import gsap from 'gsap';
import variables from '../../variables';
import isVisible, {divideNumberByThousands} from '../../helpers/_utilities';
import ScrollTrigger from 'gsap/ScrollTrigger';
import is from 'is_js';

gsap.registerPlugin(ScrollTrigger);


export default function numbersIncrement(
  duration = variables.carouselDefaultDuration / 1000 * 10,
  once = false,
  rootSelector = '.js-number-increment--root',
  outputSelector = '.js-number-increment--output'
) {

  if ($dom.exist([
    rootSelector,
    outputSelector
  ])) {
    const existingScrollTriggers = [];

    return {
      init() {

        $dom.callAll(outputSelector, output => output.innerHTML = '0');

        function animate(root) {
          $dom.each($dom.getAll(outputSelector, root), output => {

            const animatedObject = {
              value: 0,
              targetValue: +output.dataset.number,
              postfix: output.dataset.numberPostfix || ''
            };
            gsap.to(animatedObject, {
              value: animatedObject.targetValue,
              roundProps: 'value',
              duration,
              onUpdate() {
                output.innerHTML = divideNumberByThousands(animatedObject.value)
              },
              onComplete() {
                if (animatedObject.postfix) output.innerHTML += animatedObject.postfix
              }
            })
          })
        }

        $dom.callAll(rootSelector, root => {

          const alreadyInitialized = existingScrollTriggers.filter(st => {
            return st.element === root
          });

          if (!alreadyInitialized.length) {
            const scrollTrigger = ScrollTrigger.create({
              trigger: root,
              markers: false,
              start: 'top 90%',
              once,
              onEnter(opt) {
                animate(opt.trigger)
              }
            });
            existingScrollTriggers.push({
              element: root,
              scrollTrigger
            });
          } else {
            $dom.callAll(alreadyInitialized, instance => {
              const {scrollTrigger, element} = instance;

              if (isVisible(element, 10)) {
                animate(element)
              }

              scrollTrigger.enable();
              scrollTrigger.refresh();
              scrollTrigger.update();
            })
          }
        });

      },
      destroy() {
        if (is.array(existingScrollTriggers) && existingScrollTriggers.length > 0) {
          for (let i = 0; i < existingScrollTriggers.length; i++) {
            if (existingScrollTriggers[i].scrollTrigger instanceof ScrollTrigger) {
              existingScrollTriggers[i].scrollTrigger.disable(true)
            }
          }
        }

        $dom.callAll(rootSelector, root => {
          $dom.each($dom.getAll(outputSelector, root), output => {
            output.innerHTML = divideNumberByThousands(output.dataset.number) + (output.dataset.numberPostfix || '')
          })
        })
      }
    }
  }

  return false
}