import {$dom} from '../../../../helpers/dom';
import {generateId, isElement, loop, objHasProp} from '../../../../helpers/_utilities';
import createCard from './createCard';
import variables from '../../../../variables';
import is from 'is_js';
import createBackButton from './backBtn';

const {
  attr,
  prepend,
  createElement,
  get
} = $dom;

export default function createList(
  data,
  cardTemplateId,
  cardOutputPairs,
  uniqClassModifier = '',
  paths = undefined,
  routerAttr = 'data-catalog-router-link',
  listClassName = 'catalog__content',
  rowClassName = 'catalog__content-row',
  colClassName = variables.classNames.catalogPage.col
) {

  const
    listId = generateId(),
    ul = createElement('ul', {
      class: rowClassName + ` ${variables.classNames.catalogPage.cardsOutput}`,
      id: listId
    })
  ;

  function createCards(dataCards) {
    return dataCards.map(dataCard => {
      const li = createElement('li', {class: colClassName});

      const card = createCard(
        dataCard,
        cardTemplateId,
        cardOutputPairs
      );

      if (isElement(card) && card.tagName.toLowerCase() === 'a') {


        if (is.not.undefined(paths.card) && is.array(paths.card)) {
          let href = '';


          loop(paths.card, (path, i) => {
            const {title, value} = path;

            href += `${title}/${is.null(value) ? dataCard.id.toString() : value}`;

            if (i !== paths.card.length - 1) {
              href += '/'
            }
          });

          attr(card, 'href', href);
          attr(card, routerAttr, '');
        }



        /*if (is.not.undefined(paths) && is.object(paths)) {

          let href = paths.root;

          if (objHasProp(paths, 'params') && is.object(paths.params)) {
            const {params} = paths;

            // itemId!!

            if (objHasProp(params, 'itemId') && is.string(params.itemId)) {
              href += `?${params.itemId}=${dataCard.id}`;
              href += `&${itemIdKey}=${params.itemId}`;
            }

            if (objHasProp(params, nestedRouteKey) && is.string(params[nestedRouteKey])) {
              href += `&${nestedRouteKey}=${params[nestedRouteKey]}`
            }

          }

          attr(card, 'href', href);
          attr(card, routerAttr, '');
        }*/


        li.appendChild(card)
      }

      return li
    })
  }

  function printCardsList(cardsList, outputPoint) {
    loop(cardsList, card => outputPoint.appendChild(card))
  }


  const wrap = createElement('div', {class: listClassName + ' ' + uniqClassModifier});

  wrap.appendChild(ul);


  if (
    objHasProp(paths, 'back')
    &&
    is.string(paths.back)
  )
    prepend(wrap, createBackButton(paths.back, 'mb-5'));

  printCardsList(createCards(data), ul);

  function update(data) {
    printCardsList(createCards(data), get('#'+listId))
  }

  return {
    template: wrap || 'error in createList',
    update
  }
}