import {$events} from '../../helpers/events';
import {$dom} from '../../helpers/dom';
import variables from '../../variables';
import gsap from 'gsap';
import {isElement, preventDefault} from '../../helpers/_utilities';

export default function initTabs(
  rootSelectorAttr = 'data-tabs-root',
  buttonSelectorAttr = 'data-tabs-control',
  rootSelector = `[${rootSelectorAttr}]`,
  buttonSelector = `[${buttonSelectorAttr}]`,
  contentSelectorAttr = 'data-tabs-content',
  contentSelector = `[${contentSelectorAttr}]`,
) {

  if ($dom.exist([
    rootSelector,
    buttonSelector,
    contentSelector
  ])) {
    function toggleTab(event) {
      if (event.target) preventDefault(event);

      const
        currentButton = (event.target && event.target.closest) ? event.target.closest(buttonSelector) : event,
        currentRoot = currentButton.closest(rootSelector),
        otherContent = [],
        currentContent = $dom.getAll(contentSelector, currentRoot).filter(content => {
          if (content.getAttribute(contentSelectorAttr) === currentButton.getAttribute(buttonSelectorAttr)) {
            return true
          } else {
            otherContent.push(content);
            return false;
          }
        })[0]
      ;

      if (isElement(currentContent)) {

        $dom.each($dom.getAll(buttonSelector, currentRoot), button => {
          if (currentButton === button) {
            $dom.addClass(button, variables.classNames.active);
          } else {
            $dom.removeClass(button, variables.classNames.active);
          }
        });

        if (currentRoot.dataset.tabsAnimated === 'true') {

          gsap.to(otherContent, {
            height: 0,
            autoAlpha: 0,
            onStart() {
              $dom.removeClass(otherContent, variables.classNames.active)
            }
          });

          gsap.to(currentContent, {
            height: 'auto',
            autoAlpha: 1,
            onStart() {
              $dom.addClass(currentContent, variables.classNames.active)
            }
          });
        } else {

          gsap.set([...otherContent, currentContent], {
            height: 'auto',
            clearProps: 'autoAlpha'
          });

          gsap.set(otherContent, {
            display: 'none'
          });

          gsap.to(currentContent, {
            display: 'block'
          })
        }

      }
    }

    return {
      init() {
        $dom.callAll(rootSelector, root => {

          const buttons = $dom.getAll(buttonSelector, root);
          const initControl = buttons.filter(button => $dom.hasClass(button, variables.classNames.active))[0] || buttons[0]

          toggleTab(initControl);
        });

        $events.delegate.on('click tap', buttonSelector, toggleTab)
      },
      destroy() {
        $events.delegate.on('click tap', buttonSelector, toggleTab)
      }
    }
  }

  return false
}