import {loop, stringToHtmlElement, warn} from '../../../../helpers/_utilities';
import {$dom} from '../../../../helpers/dom';
import is from 'is_js';
import variables from '../../../../variables';

let template;

const {
  each,
  attr,
  getAll,
  exist
} = $dom;

function replacePlaceholder(data, dataKey, placeholderKey, template) {
  if (data.hasOwnProperty(dataKey)) {
    const resultVal = placeholderKey === 'imageSrc' ? variables.rootUrl + data[dataKey] : data[dataKey];

    return template.replace(`{${placeholderKey}}`, resultVal)
  } else {
    warn(`Key "${dataKey}" for replacing template key "${placeholderKey}" not found`, 'replacePlaceholder function');
    return template;
  }
}

export default function createCard(
  data = {},
  templateId = '',
  outputPairs = [],
  cardBackPath = null,
  routerAttr = 'data-catalog-router-link',
  backBtnSelector = '.js-catalog-back'
) {

  template = document.getElementById(templateId).innerHTML.trim();
  if (!template) return null;

  loop(outputPairs, i => template = replacePlaceholder(data, i.fromStoreKey, i.fromMarkupKey, template));

  const templateAsHtml = stringToHtmlElement(template);

  if (exist(backBtnSelector, templateAsHtml)) {
    if (is.not.null(cardBackPath) && is.string(cardBackPath)) {
      each(getAll(backBtnSelector, templateAsHtml), link => attr(link, 'href', '/'+cardBackPath))
    } else {
      each(getAll(backBtnSelector, templateAsHtml), link => link.removeAttribute(routerAttr));
    }
  }

  return templateAsHtml
}












