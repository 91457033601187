import {$dom} from '../../helpers/dom';
import {$events} from '../../helpers/events';
import variables from '../../variables';
import {isElement, loop, preventDefault} from '../../helpers/_utilities';

const {
  hasClass,
  removeClass,
  addClass,
  exist
} = $dom;

export default function initDropdown(
  rootSelector = '.js-dropdown'
) {
  const
    buttonSelector = `${rootSelector}-button`,
    contentSelector = `${rootSelector}-content`
  ;

  if (exist([
    rootSelector,
    buttonSelector,
    contentSelector
  ])) {

    let opened = [];

    const {active, disabled} = variables.classNames;

    const
      closeOpened = () => {
        if (opened.length > 0) {
          for (let i = 0; i < opened.length; i++) {
            removeClass(opened[i], variables.classNames.active)
          }
          opened = [];
        }
      },
      toggle = event => {


        if (opened.length > 0) {

          const
            currentRoot = opened.filter(root => root.contains(event.target))[0],
            isClickInside = isElement(currentRoot) || false
          ;

          if (!isClickInside) closeOpened();
        }

        if (!isElement(event.target.closest(buttonSelector))) return;

        const root = event.target.closest(rootSelector);
        if (hasClass(event.target, disabled)) return;

        preventDefault(event);

        if (hasClass(root, active)) {
          removeClass(root, active);
          if (opened.indexOf(root) >= 0) opened.splice(opened.indexOf(root), 1)
        } else {
          addClass(root, active);
          opened.push(root);
        }
      },
      init = () => {
        $events.delegate
          .on('click tap', document, toggle)
        ;
      },
      destroy = () => {
        closeOpened();
        $events.delegate
          .off('click tap', document, toggle)
        ;
      }
    ;


    return {init, destroy}
  }

  return false;
}