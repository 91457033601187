import variables from "../variables";
import is from "is_js";
import axios from 'axios';
import {$dom} from './dom';
import {$events} from './events';

export function warn(message, locationName) {
  if (variables.debugLogs) {
    console.warn(message + `. Warning source - ${locationName}`)
  }
}

export function toDashesCase(string) {
  return  string.replace(/[A-Z]/g, m => '-' + m.toLowerCase())
}

export function isElement(target){
  return (
    typeof HTMLElement === 'object' ? target instanceof HTMLElement :
      target && typeof target === 'object' && target !== null && target.nodeType === 1 && typeof target.nodeName === 'string'
  );
}

export function isNode(target){
  return (
    typeof Node === "object" ? target instanceof Node :
      target && typeof target === "object" && typeof target.nodeType === "number" && typeof target.nodeName==="string"
  );
}

export function filterStringArgs(targets) {
  return targets.toString().split(/[\s,]+/).filter(e => e.length)
}

export function optimizeTarget(target) {
  return is.not.array(target) && !isElement(target) && target !== window ? document.querySelector(target) : target;
}

export function divideNumberByThousands(num, symbol = ',') {
  return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, symbol);
}

export default function isVisible(elm, threshold, mode) {
  threshold = threshold || 0;
  mode = mode || 'visible';

  let rect = elm.getBoundingClientRect();
  let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  let above = rect.bottom - threshold < 0;
  let below = rect.top - viewHeight + threshold >= 0;

  return mode === 'above' ? above : (mode === 'below' ? below : !above && !below);
}

export function preventDefault(event) {
  (event.originalEvent || event).preventDefault();
  return event;
}

export function sleep(ms) {
  let tm = null;
  return new Promise(resolve => {
    tm = setTimeout(resolve, ms);
  }).then(() => {
    clearTimeout(tm);
    tm = null;
  });
}

export function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    let context = this, args = arguments;
    let later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

if (variables.debugLogsDisabledNotify && !variables.debugLogs) {
  console.info('%cDebug mode is disabled. You will not see any error messages from "$helpers"', 'color: orange;');
}

export async function getData(url) {
  try {
    const response = await axios.get(url);
    return response.data
  } catch (error) {
    console.log('Error in async function getData');
    console.warn(error)
  }
}

export function loop(arr, cb) {
  if (is.not.array(arr) || is.not.function(cb)) return;

  for (let i = 0; i < arr.length; i++) {
    cb(arr[i], i)
  }
}

export function forIn(obj, cb) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      cb(key, obj[key])
    }
  }
}

export function stringToHtmlElement(string) {
  let template = document.createElement('template');
  string = string.trim();
  template.innerHTML = string;
  return template.content.firstChild;
}

export function stringToHtmlElements(string) {
  let template = document.createElement('template');
  template.innerHTML = string;
  return template.content.childNodes;
}

export function objHasProp(obj, prop) {
  return obj.hasOwnProperty(prop);
}

export function isEqualArrays (value, other) {

  let type = Object.prototype.toString.call(value);

  if (type !== Object.prototype.toString.call(other)) return false;

  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

  let valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
  let otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  let compare = function (item1, item2) {

    let itemType = Object.prototype.toString.call(item1);

    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqualArrays(item1, item2)) return false;
    }

    else {

      if (itemType !== Object.prototype.toString.call(item2)) return false;

      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }

    }
  };
  if (type === '[object Array]') {
    for (let i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (let key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  return true;

}

export function convertSecondsToPlayerFormat(secs) {
  secs = Math.round(secs);

  let
    minutes = Math.floor(secs / 60) || 0,
    seconds = (secs - minutes * 60) || 0
  ;

  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
}

export const classNameToSelector = string => `.${string}`;

export function setInputFilter(fieldSelector, inputFilter = null) {

  let fltr;
  const eventTypes = ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu"];

  if (is.function(inputFilter)) {
    fltr = inputFilter
  } else if (is.regexp(inputFilter)) {
    fltr = value => inputFilter.test(value);
  }

  function handleField() {
    if (fltr(this.value)) {
      this.oldValue = this.value;
      this.oldSelectionStart = this.selectionStart;
      this.oldSelectionEnd = this.selectionEnd;
    } else if (this.hasOwnProperty("oldValue")) {
      this.value = this.oldValue;
      this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
    } else {
      this.value = '';
    }
  }

  return {
    init() {
      eventTypes.forEach(event =>  {
        $events.delegate.on(event, fieldSelector, handleField);
      });
    },
    destroy() {
      eventTypes.forEach(event => {
        $events.delegate.off(event, fieldSelector, handleField)
      })
    }
  }
}

export function addZero(val) {
  return ('0' + val).slice(-2)
}

export const getCaretPosition = e => e && e.selectionStart || 0;

export const generateId = () => '_' + Math.random().toString(36).substr(2, 9);

export const encodeUrl = value => {
  let v;

  if (is.array(value)) {
    v = value.map(val => encodeURIComponent(val))
  } else {
    v = encodeURIComponent(value)
  }

  return v
};

export const differenceBetweenArrays = (a1, a2) => {
  let a = [], diff = [];

  for (let i = 0; i < a1.length; i++) {
    a[a1[i]] = true;
  }

  for (let i = 0; i < a2.length; i++) {
    if (a[a2[i]]) {
      delete a[a2[i]];
    } else {
      a[a2[i]] = true;
    }
  }

  for (let k in a) {
    if (a.hasOwnProperty(k)) {
      diff.push(k);
    }
  }

  return diff;
};

export const toMinAndSec = value => {
  const
    sec = parseInt(value, 10),
    hours   = Math.floor(sec / 3600),
    minutes = Math.floor((sec - (hours * 3600)) / 60),
    seconds = sec - (hours * 3600) - (minutes * 60)
  ;

  return {
    minutes, seconds
  }
};