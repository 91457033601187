import {$dom} from '../helpers/dom';
import homePage from './pages/home/';
import catalogPage from './pages/catalog';
import personalPage from './pages/personal';
import cartPage from './pages/cart';
import regularPage from './pages/regular';

$dom.ready(() => {
  const currentPage = [
    regularPage,
    homePage,
    catalogPage,
    personalPage,
    cartPage
  ].filter(page => {
    if (page.exist) {
      window.currentPage = page;
      return true
    }
  })[0];

  try {
    currentPage && currentPage.init();
  } catch (e) {
    console.error(e);
  }
});