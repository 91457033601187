import merge from 'deepmerge';

export const $data = (function () {

	const localAPIs = {};

	localAPIs.deepAssign = function () {
		let len = arguments.length;
		if (len < 1) return;
		if (len < 2) return arguments[0];

		for (let i = 1; i < len; i++) {
			for (let key in arguments[i]) {
				if (arguments[i].hasOwnProperty(key)) {
					if (Object.prototype.toString.call(arguments[i][key]) === '[object Object]') {
						arguments[0][key] = localAPIs.deepAssign(arguments[0][key] || {}, arguments[i][key]);
					} else {
						arguments[0][key] = arguments[i][key];
					}
				}
			}
		}

		return arguments[0];
	};

	localAPIs.merge = merge;

  localAPIs.cloneObject = obj => JSON.parse(JSON.stringify(obj));

  localAPIs.serialize = function (data) {
    let obj = {};

    for (let [key, value] of data) {
      if (obj[key] !== undefined) {
        if (!Array.isArray(obj[key])) {
          obj[key] = [obj[key]];
        }
        obj[key].push(value);
      } else {
        obj[key] = value;
      }
    }
    return obj;
  };

	return localAPIs;

})();
