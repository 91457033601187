import {$dom} from '../../helpers/dom';
import gsap from 'gsap';

const {
  createElement,
  prepend,
  remove,
  get
} = $dom;

export default function initLoader(
  insertPointSelector = '.js-page-wrapper',
  loaderClassName = 'loader'
) {

  let animation = null, loader = null, animationDuration = null;

  const
    init = () => {
      loader = createElement('div', {class: loaderClassName});
      prepend(get(insertPointSelector), loader);
      animation = gsap.getProperty(loader, 'animation');
      animationDuration = gsap.getProperty(loader, 'animation-duration');

      gsap.set(loader, {animation: 'none'})
    },
    destroy = () => {
      remove(loader);
      loader = null;
      animation = null;
      animationDuration = null;
    },
    show = () => {
      gsap.set(loader, {
        autoAlpha: 1,
        animation
      })
    },
    hide = () => {
      gsap.set(loader, {
        delay: animationDuration / 5,
        clearProps: 'opacity,visibility',
        animation: 'none'
      })
    }
  ;

  return {init, destroy, show, hide};
}