import is from 'is_js';
import {encodeUrl} from '../../../helpers/_utilities';
import variables from '../../../variables';

const {
  filter,
  filterFormData
} = variables.storageKeys;

export default function filterParams() {
  return {
    formData: {
      save(obj) {
        if (is.empty(obj)) {
          localStorage.removeItem(filterFormData)
        } else {
          localStorage.setItem(filterFormData, JSON.stringify(obj))
        }
      },
      get() {
        const value = localStorage.getItem(filterFormData);
        return is.null(value) ? {} : JSON.parse(value)
      }
    },
    parse(querystring) {
      const
        params = new URLSearchParams(querystring),
        obj = {}
      ;

      for (const key of params.keys()) {
        if (params.getAll(key).length > 1) {
          obj[key] = params.getAll(key);
        } else {
          obj[key] = params.get(key);
        }
      }

      return obj;
    },
    configureUrl(params, encoded = true) {

      const url = Object
        .keys(params)
        .map(key => ({[key]: params[key]}))
        .reduce((acc, value, i) => {
          for (let key in value) {
            if (value.hasOwnProperty(key)) {
              let v = value[key].split(',');
              let divider = acc.indexOf('?') !== -1 ? '&' : '?';

              acc += `${divider}${key}=${v.join('$$')}`;

              return acc
            }
          }
        }, '');

      return encoded ? encodeUrl(url) : url;
    },
    save(params) {
      if (is.null(params)) {
        localStorage.removeItem(filter)
      } else {
        localStorage.setItem(filter, this.configureUrl(params))
      }
    },
    get() {
      return localStorage.getItem(filter)
    },
    get clean() {
      if (this.exist) return this.parse(decodeURIComponent(this.get()));
      return {}
    },
    get exist() {
      return this.get() !== null
    }
  }

}