import filterParams from './fiterParams';
import is from 'is_js';

const fp = filterParams();

export default {
  removeURLParameter: (url, param) => {
    let urlParts = url.split('?');

    if (urlParts.length >= 2) {

      let prefix = encodeURIComponent(param) + '=';
      let pars = urlParts[1].split(/[&;]/g);

      for (let i = pars.length; i-- > 0;) {
        if (pars[i].lastIndexOf(prefix, 0) !== -1) pars.splice(i, 1);
      }

      return urlParts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
  },
  _re(key) {
    return new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
  },
  updateQueryStringParameter(key, value, uri = window.location.href) {
    const re = this._re(key);

    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
    } else {
      return uri + (uri.indexOf('?') !== -1 ? '&' : '?') + key + "=" + value;
    }
  },
  updateHistoryState(key, value) {

    const url = this.updateQueryStringParameter(
      key,
      value
    );

    window.history.pushState(
      {},
      '',
      url
    );
  },
  pushHistoryState(url) {
    window.history.pushState(
      {},
      '',
      url
    );
  },
  removeHistoryKey(key) {
    if (Boolean(key) && is.string(key)) this.pushHistoryState(this.removeURLParameter(window.location.href, key))
  },
  getParam(key) {
    return new URLSearchParams(fp.configureUrl(fp.clean, false)).get(key)
  }
};