import {$events} from '../../../helpers/events';
import {$dom} from '../../../helpers/dom';
import gsap from 'gsap';
import variables from '../../../variables';
import {loop} from '../../../helpers/_utilities';


const {
  get,
  exist,
  hasClass,
  removeClass,
  addClass
} = $dom;

export default function toggleTrackCard(
  rootSelector= '.js-page-player-detailed',
  duration = .25
) {

  const
    triggerSelector = rootSelector+'-trigger',
    parentSelector = rootSelector+'-parent'
  ;

  let lastOpened = [];

  const expand = playerCard => {
    gsap.to(playerCard, {
      height: 'auto',
      duration
    });
    addClass(playerCard, variables.classNames.active);

    lastOpened.push(playerCard)
  };

  const collapse = playerCard => {
    gsap.to(playerCard, {
      height: 0,
      duration
    });
    removeClass(playerCard, variables.classNames.active);

    const indexOfCard = lastOpened.indexOf(playerCard);

    if (indexOfCard !== -1) {
      lastOpened.splice(indexOfCard, 1)
    }
  };

  const toggle = playerCard => {
    if (hasClass(playerCard, variables.classNames.active)) {
      collapse(playerCard)
    } else {
      expand(playerCard)
    }
  };

  const handleTriggersButton = event => {
    const parent = event.target.closest(parentSelector);
    if (parent && exist(rootSelector, parent)) {

      const currentPlayerCard = get(rootSelector, parent);

      loop(lastOpened, playerCard => {
        if (playerCard !== currentPlayerCard)
          collapse(playerCard)
      });

      toggle(currentPlayerCard);
    }
  };

  const
    init = () => {
      $events.delegate.on('click tap', triggerSelector, handleTriggersButton);
    },
    destroy = () => {
      $events.delegate.off('click tap', triggerSelector, handleTriggersButton);

      gsap.set(triggerSelector, {clearProps: 'cursor'});
    }
  ;

  return {init, destroy}
}