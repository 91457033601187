import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import {$dom} from '../../helpers/dom';
import {$style} from '../../helpers/style';
import variables from '../../variables';
import {debounce} from '../../helpers/_utilities';

gsap.registerPlugin(ScrollTrigger);

export default function initHomeAnimations(
  pageInstance,
  wrapSelector = '.js-home-purchase-steps',
  itemSelector = '.js-home-purchase-item',
  progressSelector = '.js-home-purchase-progress'
) {

  const {get, getAll} = $dom;


  const
    wrap = get(wrapSelector),
    items = getAll(itemSelector),
    progress = get(progressSelector),

    firstItem = items[0],
    lastItem = items[items.length - 1],
    firstItemOffsetLeft = $style.offset(firstItem).left,
    lastItemOffsetLeft = $style.offset(lastItem).left,
    wrapOffsetLeft = $style.offset(wrap).left,
    firstItemFromWrapOffsetLeftDifference = firstItemOffsetLeft - wrapOffsetLeft
  ;

  gsap.set(progress, {clearProps: 'x'});

  if (firstItemFromWrapOffsetLeftDifference > 0) {
    gsap.set(progress, {
      x: firstItemFromWrapOffsetLeftDifference
    })
  }

  const highLightItems = debounce(scrollTrigger => {
    if (scrollTrigger.direction < 0) {
      if (!progress.classList.contains('is-reversed')) {
        progress.classList.add('is-reversed')
      }
    } else {
      if (progress.classList.contains('is-reversed')) {
        progress.classList.remove('is-reversed')
      }
    }
    const progressWidth = gsap.getProperty(progress, 'width');
    $dom.each(items, item => {
      if (progressWidth >= ($style.offset(item).left - wrapOffsetLeft) - (firstItemOffsetLeft - wrapOffsetLeft) && scrollTrigger.progress > 0) {
        item.classList.add(variables.classNames.active)
      } else {
        item.classList.remove(variables.classNames.active)
      }
    });
  }, 10);

  ScrollTrigger.create({
    trigger: wrap,
    markers: false,
    start: 'center 95%',
    end: `+=${window.innerHeight * .75}`,
    ease: 'linear',
    scrub: true,
    onUpdate: highLightItems,
    animation: gsap.fromTo(progress, {width: 0},{
      width: lastItemOffsetLeft - firstItemOffsetLeft + lastItem.offsetWidth / 2
    })
  });

}