import {$events} from '../../helpers/events';
import is from 'is_js';
import {playbackObserver} from '../observers';
import variables from '../../variables';
import {getData, objHasProp} from '../../helpers/_utilities';
import {$dom} from '../../helpers/dom';

const {
  albums: albumsRoute,
  tracks: tracksRoute,
  playlists: playlistsRoute
} = variables.xhrRoutes;

export default class PlaybackExtractor {
  constructor() {

    this.playbackTriggerSelector = '[data-play-target-id]';
    this.datasetValue = 'playTargetId';
    this.pageInstance = null;

    this.extractFromButtonEvent = this.extractFromButtonEvent.bind(this);
  }

  _filterById(arr, id, returnEl = true) {
    let rArr = arr.filter(el => el.id === id);
    return returnEl ? rArr[0] : rArr
  }

  parseTarget(value, force = false) {

    if (value === this.playerProgressSaver.get().lastPlayTarget && !force) return;

    let
      dataRoute,
      payload = {
        tracks: [],
        startWith: 0
      }
    ;

    const
      attrValues = value.split('-'),
      targetValues = attrValues[0].split(':'),
      targetType = targetValues[0],
      trackId = +attrValues[1]
    ;


    switch (targetType) {
      case 'albums':
        dataRoute = albumsRoute;
        break;
      case 'playlists':
        dataRoute = playlistsRoute;
        break;
      case 'tracks':
        dataRoute = tracksRoute;
        break
    }

    const
      targetId = +targetValues[1],
      setPayload = (searchTarget, isTracksList = false) => {
        const tracks = isTracksList ? searchTarget : searchTarget.find(t => +t.id === +targetId).tracks;

        payload.tracks = tracks;
        payload.startWith = tracks.map(t => t.id).indexOf(trackId);
      },
      sendPayload = data => {
        console.log('targetType from sendPayload:', targetType);

        switch (targetType) {
          case 'albums':
            setPayload(data.albums);
            break;
          case 'playlists':
            setPayload(data.playlists);
            break;
          case 'tracks':
            setPayload(data.tracks, true);
            break
        }


        playbackObserver.dispatch(payload);
      }
    ;

    this.pageInstance.loader.show();
    getData(variables.xhrRootUrl+dataRoute)
      .then(data => {
        sendPayload(data);
        this.pageInstance.loader.hide();
        this.playerProgressSaver.save({lastPlayTarget: value});
      })

  }

  extractFromButtonEvent(event) {
    const button = event.target.closest(this.playbackTriggerSelector);
    this.parseTarget(button.dataset[this.datasetValue])
  }

  listeners(action) {
    switch (action) {
      case 'add':

        $events.delegate.on(
          'click tap',
          this.playbackTriggerSelector,
          this.extractFromButtonEvent
        );

        break;

      case 'remove':

        $events.delegate.off(
          'click tap',
          this.playbackTriggerSelector,
          this.extractFromButtonEvent
        );

        break;
    }
  }

  init(pageInstance) {
    this.pageInstance = pageInstance;
    this.listeners('add');

    const {
      lastPlayTarget
    } = this.playerProgressSaver.get();


    // if (is.string(lastPlayTarget)) {
    //   this.parseTarget(lastPlayTarget, true)
    // }


  }

  destroy() {
    this.pageInstance = null;
    this.listeners('remove');
  }
}
