import {$dom} from '../../helpers/dom';
import gsap from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
import variables from '../../variables';

gsap.registerPlugin(ScrollTrigger);

export default function batchAnimation(options = {}) {

  const defaults = {
    targetsSelector: `.${variables.classNames.batchTarget}`,
    stagger: .15,
    duration: .5,
    batchShift: 100,
    batchMax: 3,
    onEnterOnly: false
  };

  const opts = Object.assign(defaults, options);

  if ($dom.exist(opts.targetsSelector)) {
    const {targetsSelector, stagger, duration, batchShift, batchMax, onEnterOnly} = opts;
    const batchElements = () => $dom.getAll(targetsSelector);

    let scrollTriggerInstances = [], els;

    return {
      init() {

        els = batchElements();

        if (els.length > 0) {
          gsap.set(els, {
            y: batchShift,
            autoAlpha: 0
          });

          const scrollTriggerSettings = {
            batchMax,
            duration,
            onEnter: batch => gsap.to(batch, {
              autoAlpha: 1,
              y: 0,
              stagger,
              overwrite: true
            })
          };

          if (!onEnterOnly) {
            Object.assign(scrollTriggerSettings, {
              onLeave: batch => gsap.to(batch, {
                autoAlpha: 0,
                y: -batchShift,
                overwrite: true
              }),
              onEnterBack: batch => gsap.to(batch, {
                autoAlpha: 1,
                y: 0,
                stagger,
                overwrite: true
              }),
              onLeaveBack: batch => gsap.to(batch, {
                autoAlpha: 0,
                y: batchShift,
                overwrite: true
              })
            })
          }

          scrollTriggerInstances = ScrollTrigger.batch(els, scrollTriggerSettings)
        }

        return scrollTriggerInstances
      },
      destroy() {
        els = batchElements();
        scrollTriggerInstances = scrollTriggerInstances.filter(instance => {
          instance.kill(true);
          return false;
        });
        gsap.set(els, {clearProps: 'y,opacity,visibility'});
      }
    }
  }

  return false;
}