import {$events} from '../../helpers/events';
import {isElement, preventDefault} from '../../helpers/_utilities';
import {$dom} from '../../helpers/dom';
import variables from '../../variables';
import axios from 'axios';
import {$data} from '../../helpers/data';

const {
  get,
  getAll,
  each,
  attr,
  removeClass,
  addClass,
  hasClass,
  html,
  exist
} = $dom;

const {
  lazyLoading,
  error
} = variables.classNames;

export default function modalAuthorize(
  modalId = 'modal--authorization',
  navSelector = '.js-modal--authorization-nav',
  messageOutputSelector= '#modal--authorization-message',
  formsDataAttr =  'data-auth-form',
  url = `${variables.rootUrl}/auth/`
) {

  let
    forms = null
  ;

  const
    requestAuth = form => {
      if (isElement(form)) {

        const submitButton = get('button[type="submit"]', form);

        addClass(submitButton, lazyLoading);

        axios({
          method: 'POST',
          url,
          data: new FormData(form)
        })
          .then(({data}) => {
            const {result, text, ref} = data;
            if (result) {
              if (Boolean(ref)) window.location = ref
            } else {
              html(messageOutputSelector, text);
              addClass(messageOutputSelector, error);
            }
          })
          .finally(() => {
            removeClass(submitButton, lazyLoading);
          })
      }
    },
    handleSubmit = event => {
      event.preventDefault();
      requestAuth(event.target.closest(`[${formsDataAttr}]`));
    },
    toggleForm = arg => {

      let btn = null;

      if (arg instanceof Event) {
        preventDefault(arg);
        btn = arg.target.closest(navSelector);
      } else if (isElement(arg)) {
        btn = arg;
      }

      removeClass(navSelector, variables.classNames.active);

      if (isElement(btn)) {
        each(forms, form => {
          form.style.display = attr(form, formsDataAttr) === attr(btn, 'href') ? 'block' : 'none';
        });
        addClass(btn, variables.classNames.active)
      }
    },
    init = () => {

      if (exist('#'+modalId)) {
        forms = getAll(`[${formsDataAttr}]`, '#'+modalId);
        const activeNav = getAll(navSelector).find(nav => hasClass(nav, variables.classNames.active));

        if (isElement(activeNav)) toggleForm(activeNav);

        $events.delegate
          .on('click tap', navSelector, toggleForm)
          .on('submit', `[${formsDataAttr}]`, handleSubmit)
      }

    },
    destroy = () => {
      forms = null;

      $events.delegate
        .off('click tap', navSelector, toggleForm)
        .off('submit', `[${formsDataAttr}]`, handleSubmit)
    }
  ;

  return {
    init, destroy
  }
}