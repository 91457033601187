import {isElement, sleep} from '../../helpers/_utilities';
import is from 'is_js';
import {$dom} from '../../helpers/dom';
import gsap from "gsap";
import {$style} from '../../helpers/style';
import {$events} from '../../helpers/events';
import variables from '../../variables';

export default function initNavFollower(
  duration = .25,
  targetSelector = '.js-nav-follower-target',
  navItemSelector = '.js-nav-follower-item',
  underLineSelector = '.js-nav-follower-underline',
  switchableClassName= variables.classNames.active
) {

  if ($dom.exist([
    targetSelector,
    navItemSelector,
    underLineSelector
  ])) {
    const $self = this;

    let currentButton = null, currentTarget = null, currentUnderline = null;

    function moveTheLine(event, buttonsGroup = null) {

      if (isElement(event)) {
        currentButton = event
      } else {
        currentButton = event.target.closest(navItemSelector);
      }

      if (is.null(buttonsGroup)) {
        buttonsGroup = $dom.getAll(navItemSelector, currentButton.closest(targetSelector))
      }

      if (isElement(currentButton)) {
        if (is.null(currentTarget)) currentTarget = currentButton.closest(targetSelector);
        if (is.null(currentUnderline)) currentUnderline = $dom.get(underLineSelector, currentTarget);

        if (isElement(currentTarget) && isElement(currentUnderline)) {

          gsap.to(currentUnderline, {
            x: $style.offset(currentButton).left - ($style.offset(currentUnderline).left - gsap.getProperty(currentUnderline, 'x')),
            width: gsap.getProperty(currentButton, 'width'),
            duration
          });

          $dom.removeClass(buttonsGroup, switchableClassName);
          $dom.addClass(currentButton, switchableClassName);

          currentButton = null;
          currentTarget = null;
          currentUnderline = null;
        }
      }
    }

    return {
      init() {

        $events.delegate.on('click tap', navItemSelector, moveTheLine);

        $dom.callAll(targetSelector, target => {

          const buttonsGroup = $dom.getAll(navItemSelector, target);

          const initialUnderlined = buttonsGroup.filter(button => {
            return $dom.hasClass(button, switchableClassName)
          })[0];

          if (isElement(initialUnderlined)) {
            sleep(50)
              .then(moveTheLine.bind(null, initialUnderlined, buttonsGroup))
          }
        });

        return $self;
      },
      destroy() {

        $events.delegate.off('click tap', moveTheLine);

        gsap.set(underLineSelector, {clearProps: 'x,width'});

        currentButton = null;
        currentTarget = null;
        currentUnderline = null;
        return $self;
      }
    }
  }

  return false
}