import Page from '../../сlasses/Page';
import sortingPanel from '../../components/sorting';
import setPersonalView from '../utils/setPersonalView';
import prepareData from '../utils/prepareData';
import createTracksList from '../catalog/templates/createTracksList';
import {$dom} from '../../../helpers/dom';
import Player from '../../сlasses/Player';
import PlaybackExtractor from '../../сlasses/PlaybackExtractor';
import variables from '../../../variables';
import playerProgressSaver from '../utils/playerProgressSaver';
import {trackListCreateObserver} from '../../observers';
import {getData} from '../../../helpers/_utilities';

const {
  get,
  html,
  append
} = $dom;

const cartPage = new Page({
  onInit() {
    this.sortingPanels = sortingPanel({
      targets: [
        '.js-cart-profiles--select'
      ]
    }).create();

    this.musicPlayer = new Player({
      name: 'cartMusicPlayer',
      rootElementId: 'js-page-player'
    });

    this.playbackExtractor = new PlaybackExtractor(variables.xhrRootUrl);

    this.musicPlayer.progressSaver = playerProgressSaver;
    this.playbackExtractor.playerProgressSaver = playerProgressSaver;

    getData(variables.xhrRootUrl+'getTracks')
      .then(data => {
        const trackList = createTracksList(
          data,
          'js-cart-template--player',
          [
            {
              fromStoreKey: 'name',
              fromMarkupKey: 'title'
            },
            {
              fromStoreKey: 'code',
              fromMarkupKey: 'code'
            },
            {
              fromStoreKey: 'price',
              fromMarkupKey: 'price'
            }
          ],
          {
            printIndex: true
          }
        );

        const outputPoint = get('.js-cart-list-output', this.rootEl);

        html(outputPoint, '');
        append(outputPoint, trackList.template);

        this.imagesLazyLoad.instance.update()
      })

/*    prepareData()
      .then(data => {

        this.musicPlayer = new Player({
          name: 'cartMusicPlayer',
          rootElementId: 'js-page-player'
        });

        this.playbackExtractor = new PlaybackExtractor(variables.xhrRootUrl);

        this.musicPlayer.progressSaver = playerProgressSaver;
        this.playbackExtractor.playerProgressSaver = playerProgressSaver;

        const
          outputPoint = get('.js-cart-list-output', this.rootEl),
          trackList = createTracksList(
            data.cart,
            'js-cart-template--player',
            [
              {
                fromStoreKey: 'name',
                fromMarkupKey: 'title'
              },
              {
                fromStoreKey: 'code',
                fromMarkupKey: 'code'
              },
              {
                fromStoreKey: 'price',
                fromMarkupKey: 'price'
              }
            ]
          );

        html(outputPoint, '');
        append(outputPoint, trackList.template);

        this.musicPlayer.init();
        this.playbackExtractor.init(cartPage);

        trackListCreateObserver.dispatch(trackList.players);

      })*/
  },
  name: 'cart',
  rootElementId: 'js-cart-page',
  resizeDependentMethods: [
    setPersonalView
  ]
});

export default cartPage;