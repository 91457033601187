import Scrollbar from 'smooth-scrollbar';
import {$events} from '../../helpers/events';
import is from 'is_js';
import variables from '../../variables';
import {$dom} from '../../helpers/dom';
import {$style} from '../../helpers/style';
import {preventDefault} from '../../helpers/_utilities';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const {
  getAll,
  callAll,
  hasClass,
  exist
} = $dom;

export default function smoothScrollbar(
  classes = {
    targets: 'js-smooth-scroll-target',
    saveDistanceTrigger: 'js-smooth-scroll-save-distance',
    isMainScrollTrigger: 'js-is-main-scroll',
    toTopButton: 'js-scroll-to-top'
  }
) {



  let currentScroll, scrollbars = [];

  const cl = cl => `.${cl}`;

  const smoothScrollTargets = () => exist(cl(classes.targets)) ? getAll(cl(classes.targets)) : [];

  function distance(action, index, scrollValue) {
    let i = index + 1;
    let key = 'lastScrollY' + '-id-' + i;

    switch (action) {
      case 'save':
        localStorage.setItem(key, scrollValue);
        break;
      case 'get':
        return +localStorage.getItem(key)
    }
  }

  function create(targets, options = {}) {

    if (is.not.mobile()) {
      const opts = Object.assign(variables.scrollbarDefaults, options);

      callAll(targets, (target, index) => {

        if (!Scrollbar.get(target)) {
          $style.set(target, 'height', '100%');

          let currentScrollBar = Scrollbar.init(target, opts);
          currentScrollBar.update();

          if (hasClass(target, classes.saveDistanceTrigger)) {
            currentScroll = currentScrollBar.offset.y;
            currentScrollBar.addListener(event => currentScroll = event.offset.y);

            $events.delegate.on('beforeunload', window, () => {
              distance('save', index, currentScroll);
            });

            currentScrollBar.scrollTo(0, distance('get', index), variables.carouselDefaultDuration);
          }

          if (hasClass(target, classes.isMainScrollTrigger)) {

            ScrollTrigger.scrollerProxy(target, {
              scrollTop(value) {
                if (arguments.length) {
                  currentScrollBar.scrollTop = value;
                }

                return currentScrollBar.scrollTop
              }
            });

            currentScrollBar.addListener(ScrollTrigger.update);

            ScrollTrigger.defaults({ scroller: target });
          }

          scrollbars.push(currentScrollBar);
        }
      });

      $events.delegate.on('tap click', cl(classes.toTopButton), event => {
        preventDefault(event);
        scrollbars.filter(scrollbar => {
          return hasClass(scrollbar.containerEl, classes.isMainScrollTrigger)
        })[0].scrollTo(0, 0, variables.carouselDefaultDuration)
      });

      Scrollbar.detachStyle();
    }

    return scrollbars;
  }


  return {
    create,
    initAll() {
      return create(smoothScrollTargets())
    },
    destroy(targets = null) {
      if (is.not.null(targets)) {
        $dom.callAll(targets, target => {
          $style.remove(target, 'height');

          let scrollbar = Scrollbar.get(target);

          if (Boolean(scrollbar)) {
            let index = scrollbars.indexOf(scrollbar);

            if (index >= 0) {
              scrollbars.splice(scrollbars.indexOf(scrollbar), 1);
              scrollbar.destroy();
            }
          }
        })
      } else {
        $style.remove(smoothScrollTargets(), 'height');
        Scrollbar.destroyAll();

        scrollbars = [];
      }

      return scrollbars

    },
    get list() {
      return scrollbars
    }
  }
}