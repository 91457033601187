import {$dom} from '../../../helpers/dom';
import {isElement} from '../../../helpers/_utilities';
import is from 'is_js';
import gsap from 'gsap';
import variables from '../../../variables';

export default function setCatalogViewSizes() {
  const targetContainer = $dom.get('.container--large', variables.dom.header);

  if (is.not.null(targetContainer) && isElement(targetContainer)) {

    const
      holder = $dom.get('.js-catalog-holder'),
      main = $dom.get('.js-catalog-main', holder),
      headerContainer = $dom.get('.container--large', variables.dom.header),
      catalogHeader = $dom.get('.js-catalog-header', holder),
      catalogContent = $dom.get('.'+variables.classNames.catalogPage.content, holder),
      {set, getProperty} = gsap,

      availableViewportHeight = variables.windowHeight - (gsap.getProperty(variables.dom.header, 'height') + gsap.getProperty(variables.dom.player, 'height')),
      headerContainerOffsetRight = window.innerWidth - headerContainer.getBoundingClientRect().right
    ;


    set(holder, {clearProps: 'height'});
    set(main, {clearProps: 'marginRight'});
    set(catalogContent, {clearProps: 'height'});

    set(holder, {
      height: availableViewportHeight + 'px'
    });

    set(main, {
      marginRight: headerContainerOffsetRight
    });

    const hh = catalogHeader.offsetParent === null ? 0 : getProperty(catalogHeader, 'height');

    set(catalogContent, {
      height: availableViewportHeight - hh + 'px'
    });

    set(document.body, {overflow: 'hidden'});
  }

}






