import {
  convertSecondsToPlayerFormat, generateId,
  loop,
  stringToHtmlElement
} from '../../../../helpers/_utilities';
import {$dom} from '../../../../helpers/dom';
import createCard from './createCard';
import is from 'is_js';
import variables from '../../../../variables';
import createBackButton from './backBtn';
import {$data} from '../../../../helpers/data';

const {createElement, exist, get, remove, attr, html, addClass, prepend} = $dom;

export default function createTracksList(
  tracks,
  cardTemplateId,
  cardOutputPairs,
  opts = {}
) {

  const defaults = {
    printIndex: true,
    back: false,
    playTargetOptions: {},
    listClassName: variables.classNames.catalogPage.trackList,
    totalDurationOutputSelector: '.js-page-player-song-duration-total',
    detailedSelector: '.js-page-player-detailed'
  };

  const options = $data.merge(defaults, opts);

  const
    listId = generateId(),
    ul = createElement('ul', {
      class: options.listClassName + ' ' + variables.classNames.catalogPage.cardsOutput,
      id: listId
    }),
    players = [],
    {parent, parentId} = options.playTargetOptions,
    configurePlayTargetAttr = trackId => {
      let result = parent;

      if (is.not.undefined(parentId)) result += `:${parentId}`;

      result += `-${trackId}`;

      return result
    }
  ;

  let numbering = 0;

  function createTrackListItems(tracks) {

    return tracks.map(track => {
      const

        card = createCard(
          track,
          cardTemplateId,
          cardOutputPairs
        ),
        indexOutputSelector = '.js-page-player-track-index-output',
        tagsOutputSelector = '.js-page-player-track-tags-output',
        playButtonSelector = '.js-page-player-track-play'
      ;

      if (card) {

        if (exist(indexOutputSelector, card)) {
          const indexOutput = get(indexOutputSelector, card);

          if (options.printIndex) {
            numbering += 1;

            if (numbering < 100) {
              indexOutput.innerHTML = ('0' + numbering).slice(-2);
            } else {
              indexOutput.innerHTML = numbering;
            }
            addClass(card, 'index-is-printed')
          } else {
            remove(indexOutput)
          }
        }

        if (exist(tagsOutputSelector, card)) {
          const tagsOutput = get(tagsOutputSelector, card);

          if (track.tags.length > 0 && !(track.tags.length === 1 && track.tags[0] === '-')) {
            loop(track.tags, tag => {
              const li = createElement('li');
              li.innerHTML = tag;
              tagsOutput.appendChild(li);
            })
          } else {
            remove(tagsOutput)
          }
        }

        if (exist(playButtonSelector, card)) {
          attr(
            get(playButtonSelector, card),
            variables.dataAttributes.playTarget,
            configurePlayTargetAttr(track.id)
          )
        }

        if (exist(options.totalDurationOutputSelector, card)) {
          let howl = new Howl({
            src: variables.rootUrl + track.src,
            html5: true,
            preload: 'metadata'
          });

          howl.on('load', () => {
            html(
              get(options.totalDurationOutputSelector, card),
              convertSecondsToPlayerFormat(howl.duration())
            );

            howl.unload();
          });

          if (exist(options.detailedSelector, card)) {

            const detailed = get(options.detailedSelector, card);



            if (is.array(track.info) && track.info.length > 0) {

              const list = get(`${options.detailedSelector}-list`, detailed);



              loop(track.info, pair => {
                const li = createElement('li');

                li.appendChild(html(createElement('span'), pair.key));
                li.appendChild(html(createElement('span'), pair.value));



                list.appendChild(li);
              })
            } else {
              remove(detailed)
            }
          }
        }

        return {card: card, track}
      } else {
        return {card: null, track}
      }

    });
  }

  function printTrackList(trackList, outputPoint) {
    loop(trackList, ({card, track}) => {
      const li = createElement('li');

      li.appendChild(card);

      players.push({card, track});
      outputPoint.appendChild(li);
    })
  }

  printTrackList(createTrackListItems(tracks), ul);

  const wrap = createElement('div', {class: 'catalog__content'});
  wrap.appendChild(ul);


  if (Boolean(options.back))
    prepend(wrap, createBackButton(options.back, 'mb-5'));

  let t = tracks.length > 0 ? wrap : stringToHtmlElement('<p>Треки отсутствуют...</p>');

  function update(tracks) {
    printTrackList(createTrackListItems(tracks), get('#'+listId));
  }

  return {
    template: t,
    get players() {
      return players
    },
    update
  }
}