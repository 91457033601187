import {catalogRenderObserver} from '../../observers';
import {
  classNameToSelector,
  debounce,
  forIn,
  getData,
  isElement,
  loop,
  objHasProp,
  warn
} from '../../../helpers/_utilities';
import variables from '../../../variables';
import createList from './templates/createList';
import createCard from './templates/createCard';
import gsap from 'gsap';
import setCatalogViewSizes from './viewSizes';
import {$dom} from '../../../helpers/dom';
import createTracksList from './templates/createTracksList';
import {$style} from '../../../helpers/style';
import SmoothScrollbar from 'smooth-scrollbar';
import is from 'is_js';
import initWaves from '../../components/waves';
import {$data} from '../../../helpers/data';
import createLoadMoreBtn from './templates/loadMoreBtn';
import {$events} from '../../../helpers/events';
import Scrollbar from 'smooth-scrollbar';
import fiterParams from './fiterParams';
import filterUrlManager from "./filterUrlManager";

const {
  get,
  append,
  addClass,
  removeClass,
  attr,
  createElement,
  hasClass
} = $dom;

const {
  collections: collectionsRoute,
  albums: albumsRoute,
  tracks: tracksRoute,
  playlists: playlistsRoute
} = variables.xhrRoutes;

const {
  classNames
} = variables;

const filterParamsManager = fiterParams();

export default class CatalogRenderer {
  constructor(baseUrl, rootId) {

    this.baseUrl = baseUrl;
    this.pageInstance = null;
    this.rootEl = document.getElementById(rootId);

    /*    this.tracksSortingPanels = sortingPanel({
          targets: ['.js-page-player-song-download-select', '.js-catalog-page-sorting--global']
        });
        this.sortingPanels = sortingPanel({
          targets: [
            '.js-catalog-page-sorting--global',
            '.js-catalog-page-sorting--detailed'
          ]
        });*/


    this.loadMoreButtonElementClassName = 'js-catalog-load-more';
    this.viewSmoothScrollbar = Scrollbar.get(get('.'+classNames.catalogPage.content));

    this.viewLoader = {
      show: () => {
        addClass(
          classNameToSelector(classNames.catalogPage.content),
          `${classNames.lazyLoading}`
        );
        this.pageInstance.loader.show();
      },
      hide: () => {
        removeClass(
          classNameToSelector(classNames.catalogPage.content),
          `${classNames.lazyLoading}`
        );
        this.pageInstance.loader.hide();
      }
    };
    this.viewScrollbar = Scrollbar.get(get('.'+classNames.catalogPage.content));

    this.detailedTracksOutputSelector = '#js-catalog-page-detailed-tracks';
    this.detailedCardHeaderSelector = '#js-catalog-detailed-header';

    this.emptyResultsSelector = '#js-catalog-empty';

    this.detailedTracksScrollbar = null;

    this.pagination = {
      currentUrl: '',
      isLastPage: false,
      targetPage: 2,
      byScrollPercent: 75,
      uploadedByScroll: false
    };

    this.dataPayload = {
      _p: null,
      get current() {
        // console.log('dataPayload current is ', this._p);
        return this._p
      },
      set value(val) {
        // console.log('new value dataPayload is ', val);
        this._p = val
      }
    };

    this.loadMoreButtonElementClassName = 'js-catalog-load-more';
    this.loadMoreButtonElement = createLoadMoreBtn(`catalog__loadMore ${this.loadMoreButtonElementClassName}`);
    this.loadMoreWay = 'scroll'; // scroll or click
    this.loadMoreByClick = isElement(this.loadMoreButtonElement) && this.loadMoreWay === 'click';
    this.loadMoreButton = {
      show: (appendPoint = this.rootEl) => {
        if (this.loadMoreByClick) {
          const div = createElement('div', {
            class: 'pt-3 pb-5'
          });

          if (hasClass(this.loadMoreButtonElement, classNames.lazyLoading))
            removeClass(this.loadMoreButtonElement, classNames.lazyLoading);

          div.appendChild(this.loadMoreButtonElement);
          appendPoint.appendChild(div);
        }
      },
      hide: () => {
        if (this.loadMoreByClick) this.loadMoreButtonElement.remove();
      },
      loading: {
        show: () => {
          if (this.loadMoreWay === 'click') addClass('.'+this.loadMoreButtonElementClassName, classNames.lazyLoading);
        },
        hide: () => {
          if (this.loadMoreWay === 'click') removeClass('.'+this.loadMoreButtonElementClassName, classNames.lazyLoading);
        }
      }
    };

    this.cardDataPairs = {
      collections: [
        {
          fromStoreKey: 'poster',
          fromMarkupKey: 'imageSrc'
        },
        {
          fromStoreKey: 'name',
          fromMarkupKey: 'title'
        },
        {
          fromStoreKey: 'description',
          fromMarkupKey: 'description'
        },
        {
          fromStoreKey: 'tracksQuantity',
          fromMarkupKey: 'tracksQuantity'
        },
        {
          fromStoreKey: 'albumsQuantity',
          fromMarkupKey: 'albumsQuantity'
        }
      ],
      albums: [
        {
          fromStoreKey: 'poster',
          fromMarkupKey: 'imageSrc'
        },
        {
          fromStoreKey: 'name',
          fromMarkupKey: 'title'
        },
        {
          fromStoreKey: 'tracksQuantity',
          fromMarkupKey: 'tracksQuantity'
        },
        {
          fromStoreKey: 'releaseDate',
          fromMarkupKey: 'releaseDate'
        }
      ],
      tracks: [
        {
          fromStoreKey: 'albumPoster',
          fromMarkupKey: 'imageSrc'
        },
        {
          fromStoreKey: 'name',
          fromMarkupKey: 'title'
        }
      ],
      playlists: [
        {
          fromStoreKey: 'poster',
          fromMarkupKey: 'imageSrc'
        },
        {
          fromStoreKey: 'name',
          fromMarkupKey: 'title'
        }
      ],
      detailed: [
        {
          fromStoreKey: 'poster',
          fromMarkupKey: 'imageSrc'
        },
        {
          fromStoreKey: 'name',
          fromMarkupKey: 'title'
        },
        {
          fromStoreKey: 'description',
          fromMarkupKey: 'description'
        },
      ]
    };

    this.cardTemplateIds = {
      collections: 'js-catalog-card-template--collection',
      albums: 'js-catalog-card-template--album',
      tracks: 'js-player-template--extended--default',
      playlists: 'js-catalog-card-template--playlist',
      detailed: 'js-catalog-card-template--detailed'
    };

    this.cardUniqClassNames = {
      collections: 'catalog__content--collections',
      albums: 'catalog__content--albums',
      tracks: '',
      playlists: 'catalog__content--playlists',
      detailed: 'catalog__content--detailed'
    };

    this.detailedRoutes = {
      album: 'getAlbumInfo',
      playlist: 'getPlaylistInfo'
    };

    this.itemsPerPage = {
      collections: 8,
      albums: 16,
      tracks: 8,
      playlists: 15,
      detailed: 8
    };

    this.pageList = {
      current: null,
      contentType: null,
      viewKey: null,
      createCards: (data, options = {}) => {

        this.pageList.viewKey = null;

        const {viewKey, paths} = options;


        if (is.string(viewKey) && viewKey.length > 0) {
          this.pageList.current = createList(
            data,
            this.cardTemplateIds[viewKey],
            this.cardDataPairs[viewKey],
            this.cardUniqClassNames[viewKey],
            paths
          );

          this.pageList.viewKey = viewKey;

          return this.pageList.current.template
        }

        return null
      },
      createTracks: (data, options = {}) => {
        this.pageList.viewKey = options.viewKey || 'tracks';

        this.pageList.current = createTracksList(
          data,
          this.cardTemplateIds.tracks,
          this.cardDataPairs.tracks,
          options
        );

        return this.pageList.current.template
      },
      render: (data, options = {}) => {
        gsap.set(classNameToSelector(classNames.catalogPage.header), {display: 'block'});

        let template;
        const {viewKey, contentType} = options;

        switch (contentType) {
          case 'cards':
            if (data.length === 0) {
              template = get(this.emptyResultsSelector).innerHTML
            } else {
              template = this.pageList.createCards(data, options);
              this.pageList.contentType = 'cards';
            }

            break;
          case 'tracks':
            options.viewKey = 'tracks';
            template = this.pageList.createTracks(data, options);
            this.pageList.contentType = 'tracks';
            break;
        }

        this.renderTemplate(template);
        this.dataPayload.value = data;

        if (data.length >= this.itemsPerPage[viewKey]) {
          this.loadMoreButton.show();
        }
        this.pageList.viewKey = viewKey;

        return template
      },
      update: data => {
        if (is.array(data)) {
          this.pageList.current.update(data);

          this.dataPayload.value = [
            ...this.dataPayload.current,
            ...data
          ];


          if (data.length < this.itemsPerPage[this.pageList.viewKey] || data.length === 0) {
            this.loadMoreButton.hide();
            this.pagination.isLastPage = true;
          }
        } else {
          this.loadMoreButton.hide();
          this.pagination.isLastPage = true;
        }

        this.updateView();
      }
    };

    this.pageDetailed = {
      current: null,
      createTracksList: (data, detailedCard) => {
        const
          tracksOutput = get(this.detailedTracksOutputSelector, detailedCard),
          cardHeader = get(this.detailedCardHeaderSelector, detailedCard)
        ;

        const list = this.pageList.createTracks(data, {viewKey: 'detailed'});

        append(
          tracksOutput,
          list
        );

        addClass(list, this.cardUniqClassNames.detailed);

        const
          availableTrackListHeight = variables.windowHeight - (
            $style.offset(cardHeader).top + cardHeader.offsetHeight + variables.dom.player.offsetHeight
          )
        ;

        gsap.set(tracksOutput, {clearProps: 'height'});
        gsap.set(tracksOutput, {height: availableTrackListHeight + 'px'});

        SmoothScrollbar.init(tracksOutput, {alwaysShowTracks: true});
        SmoothScrollbar.detachStyle();

        this.loadMoreButton.show(get('.'+this.cardUniqClassNames.detailed, tracksOutput));

        if (this.loadMoreWay === 'scroll') {
          this.detailedTracksScrollbar = Scrollbar.get(tracksOutput);


          this.detailedTracksScrollbar.removeListener(this.loadMoreByScroll);
          this.detailedTracksScrollbar.addListener(this.loadMoreByScroll);
        }


        return list;
      },
      renderTracks: (cardTemplate, tracksUrl) => {
        return new Promise((resolve, reject) => {
          getData(variables.xhrRootUrl+tracksUrl)
            .then(data => {
              this.pageDetailed.createTracksList(data, cardTemplate);

              this.dataPayload.value = data;

              this.updateView();
              resolve(this.dataPayload.current);
            })
            .catch(reject)
        });
      },
      render: (data, tracksUrl, backPath = null) => {
        gsap.set(classNameToSelector(classNames.catalogPage.header), {display: 'none'});

        const detailedCard = createCard(
          data,
          this.cardTemplateIds.detailed,
          this.cardDataPairs.detailed,
          backPath
        );

        this.renderTemplate(detailedCard);

        this.pageDetailed.renderTracks(detailedCard, tracksUrl);

        this.pagination.currentUrl = variables.xhrRootUrl+tracksUrl;
      }
    };

    this.loadMore = this.loadMore.bind(this);
    this.loadMoreByScroll = this.loadMoreByScroll.bind(this);
    this.renderCollections = this.renderCollections.bind(this);
    this.renderCollectionAlbums = this.renderCollectionAlbums.bind(this);
    this.renderAlbums = this.renderAlbums.bind(this);
    this.renderTracks = this.renderTracks.bind(this);
    this.detailedRender = this.detailedRender.bind(this);
    this.renderPlaylists = this.renderPlaylists.bind(this);

    this.debouncedLoadByScroll = debounce(() => {
      this.loadMore();
      // .then(payload => {
      //   console.log('loadMoreByScroll! total payload is ', payload)
      // });
      this.viewScrollbar.update();
      this.pagination.uploadedByScroll = true;
    }, variables.resizeDebounce);
  }

  _getDataRoute(routeName) {

    switch (routeName) {
      case 'collections':
        return collectionsRoute;
      case 'albums':
        return albumsRoute;
      case 'tracks':
        return tracksRoute;
      case 'playlists':
        return playlistsRoute;
      default:
        return ''
    }

  }

  _resetPagination() {
    this.pagination.targetPage = 2;
    this.pagination.isLastPage = false;
    this.pagination.uploadedByScroll = false;
  }

  updateView() {
    this.pageInstance.imagesLazyLoad.instance.update();
    this.pageInstance.router.navigo.updatePageLinks();

    setCatalogViewSizes();
  }

  renderTemplate(template, cb = null) {

    this.rootEl.innerHTML = '';

    this._resetPagination();

    if (isElement(template)) {
      this.rootEl.appendChild(template)
    } else {
      this.rootEl.innerHTML = template
    }

    initWaves();
    this.updateView();

    if (is.function(cb)) cb(template);

  }

  loadMore() {

    return new Promise((resolve, reject) => {

      const {currentUrl} = this.pagination;

      if (is.string(currentUrl) && currentUrl.length > 0) {

        if (!this.pagination.isLastPage) {
          this.pageInstance.loader.show();
          this.loadMoreButton.loading.show();

          if (this.loadMoreWay === 'scroll') addClass('.'+classNames.catalogPage.main, 'is-scroll-loading');

          getData(
            variables.xhrRootUrl + currentUrl + `${currentUrl.includes('?') ? '&' : '?'}page=${this.pagination.targetPage}`)
            .then(data => {
              this.pageList.update(data);

              this.pagination.targetPage += 1;
              this.pagination.uploadedByScroll = false;

              resolve(this.dataPayload.current);
            })
            .catch(e => {
              warn('Error in getData', 'loadMoreByClickHandler method of CatalogRenderer');
              reject(e);
              throw new Error(e);
            })
            .finally(() => {
              this.pageInstance.loader.hide();
              this.loadMoreButton.loading.hide();
              this.updateView();

              if (this.loadMoreWay === 'scroll') removeClass('.'+classNames.catalogPage.main, 'is-scroll-loading')
            })
        }


      } else {
        const m = 'Incorrect CatalogRenderer.pagination.currentUrl';

        warn(m, 'loadMore method of CatalogRenderer');
        reject(m);
      }
    });
  }

  loadMoreByScroll({offset, limit}) {
    if (
      (offset.y === limit.y && offset.y !== 0)
      ||
      (offset.y / limit.y * 100 > this.pagination.byScrollPercent)) {
      if (!this.pagination.uploadedByScroll && !this.pagination.isLastPage) {
        this.debouncedLoadByScroll()
      }
    }
  }

  mainRender(opts = {}, actionCb) {

    this.rootEl.innerHTML = '';

    this.pageInstance.loader.show();
    this.viewLoader.show();
    addClass('.'+classNames.catalogPage.header, classNames.disabled);

    this._resetPagination();

    if (opts.hasOwnProperty('apiRoute') && opts.hasOwnProperty('urlRoot')) {
      const {apiRoute, urlRoot} = opts;
      this.pagination.currentUrl = apiRoute;

      catalogRenderObserver.dispatch();

      const {params} = this.pageInstance.router.navigo.getCurrentLocation();

      let apiUrl = `${variables.xhrRootUrl}${apiRoute}`;

      if (is.not.null(params) && apiRoute !== 'getPlaylists') {
        apiUrl += `/filter${filterParamsManager.configureUrl(params, false)}`;
      }

      getData(apiUrl)
        .then(response => {
          actionCb(response);
        })
        .catch(e => {
          this.rootEl.innerText = 'Ошибка загрузки mainRender';
          throw new Error(e);
        })
        .finally(() => {
          this.pageInstance.loader.hide();
          this.viewLoader.hide();
          removeClass('.'+classNames.catalogPage.header, classNames.disabled);
        })
    }
  }

  detailedRender(match) {

    const {route, data} = match;

    let detailedPath = '', detailedTracksPath = '', backPath = '';

    const
      isCollectionAlbum = (route.path.includes('collections') && route.path.includes('album')),
      isAlbum = route.path.includes('albums'),
      isPlaylist = route.path.includes('playlists')
    ;

    catalogRenderObserver.dispatch();

    if (isCollectionAlbum || isAlbum) {
      detailedPath = 'getAlbumInfo';
      detailedTracksPath = 'albumId';
    } else if (isPlaylist) {
      detailedPath = 'getPlaylistInfo';
      detailedTracksPath = 'playlistId';
    }

    if (isAlbum) backPath = '/albums';
    if (isPlaylist) backPath = '/playlists';

    if (detailedPath.length > 0) {
      let url = variables.xhrRootUrl+`${detailedPath}?id=${data.id}`;

      getData(url)
        .then(detailedInfo => {

          if (isCollectionAlbum && detailedInfo.hasOwnProperty('parentCollectionId')) {
            const {parentCollectionId} = detailedInfo;

            backPath = 'collections/'+parentCollectionId
          }


          this.pageDetailed.render(detailedInfo, `getTracks?${detailedTracksPath}=${detailedInfo.id}`, backPath);
        })
    }
  }

  renderCollections(match) {

    // let url = `${variables.xhrRootUrl}getCollections`;

    this.mainRender({
      apiRoute: 'getCollections',
      urlRoot: 'collections'
    }, response => {
      this.pageList.render(
        response,
        {
          viewKey: 'collections',
          contentType: 'cards',
          paths: {
            card: [
              {
                title: 'collections',
                value: null
              }
            ]
          }
        }
      );
    })
  }

  renderCollectionAlbums(match) {

    const {data} = match;

    //let url = `${variables.xhrRootUrl}getAlbums?collectionId=${data.id}`;

    this.mainRender( {
      apiRoute: 'getAlbums',
      id: data.id,
      urlRoot: 'collections'
    }, response => {
      this.pageList.render(
        response,
        {
          viewKey: 'albums',
          contentType: 'cards',
          paths: {
            card: [
              {
                title: 'collections',
                value: data.id
              },
              {
                title: 'album',
                value: null
              }
            ],
            back: 'collections'
          }
        }
      )
    });
  }

  renderAlbums(match) {

    // let url = `${variables.xhrRootUrl}getAlbums`;

    this.mainRender({
      apiRoute: 'getAlbums',
      urlRoot: 'albums'
    }, response => {
      this.pageList.render(
        response,
        {
          viewKey: 'albums',
          contentType: 'cards',
          paths: {
            card: [
              {
                title: 'albums',
                value: null
              }
            ]
          }
        }
      )
    });
  }

  renderTracks(match) {

    // let url = `${variables.xhrRootUrl}getTracks`;

    this.mainRender({
      apiRoute: 'getTracks',
      urlRoot: 'tracks'
    }, response => {

      this.pageList.render(
        response,
        {
          printIndex: true,
          viewKey: 'tracks',
          contentType: 'tracks'
        }
      );
    })
  }

  renderPlaylists(match) {

    //let url = `${variables.xhrRootUrl}getPlaylists`;

    this.mainRender({
      apiRoute: 'getPlaylists',
      urlRoot: 'playlists'
    }, response => {
      this.pageList.render(
        response,
        {
          viewKey: 'playlists',
          contentType: 'cards',
          paths: {
            card: [
              {
                title: 'playlists',
                value: null
              }
            ]
          }
        }
      );
    })
  }

  listeners(action) {
    switch (action) {
      case 'add':

        switch (this.loadMoreWay) {
          case 'click':
            $events.delegate
              .on('click tap', '.'+this.loadMoreButtonElementClassName, this.loadMore)
            ;
            break;
          case 'scroll':
            if (this.viewSmoothScrollbar) {
              this.viewSmoothScrollbar.addListener(this.loadMoreByScroll)
            }
            break;
        }

        break;

      case 'remove':

        switch (this.loadMoreWay) {
          case 'click':
            $events.delegate
              .off('click tap', '.'+this.loadMoreButtonElementClassName, this.loadMore)
            ;
            break;
          case 'scroll':
            if (this.viewSmoothScrollbar) {
              this.viewSmoothScrollbar.removeListener(this.loadMoreByScroll)
            }
            break;
        }
        break;
    }
  }

  init(pageInstance) {

    this.pageInstance = pageInstance;

    this.listeners('add');
  }

  destroy () {
    this.pageInstance = null;

    this.listeners('remove');
  }
}

