import Page from '../../сlasses/Page';
import carouselsData from '../../carouselsData/home'
import initHomeAnimations from '../../animations/home';

const homePage = new Page({
  onInit: initHomeAnimations,
  batch: true,
  name: 'home',
  rootElementId: 'js-home-page',
  carousels: carouselsData,
/*  searchSettings: {
    onInit() {
      console.log('searchSettings onInit')
    },
    onDestroy() {
      console.log('searchSettings onDestroy')
    }
  },
  onInit() {
    console.log('homePage onInit')
  },
  onDestroy() {
    console.log('homePage onDestroy')
  }*/
});

export default homePage;