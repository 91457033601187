import {getData} from '../../../helpers/_utilities';
import variables from '../../../variables';

export default function prepareData() {
  sessionStorage.clear();

  return getData(`${variables.xhrRootUrl}/store`)
    .then(data => {

      sessionStorage.setItem(variables.savedDataStorageKey, JSON.stringify(data));
      return data
    })
}
