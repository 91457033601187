import {loop, objHasProp, stringToHtmlElement} from '../../helpers/_utilities';
import {$dom} from '../../helpers/dom';
import Choices from 'choices.js';
import variables from '../../variables';
import is from 'is_js';

const {callAll, createElement, attr, html, exist} = $dom;

export default function sortingPanel(opts = {}) {

  const defaults = {
    method: 'static',
    targets: []
  };

  const options = Object.assign(defaults, opts);
  const targets = options.targets;
  let createdChoices = [];
  const toStr = val => String(val);

  const
    create = () => {
      switch (options.method) {
        case 'static':

          if (targets.length > 0) {

            const appendIcon = (customProperties, cb) => {
              const props = JSON.parse(customProperties);

              if (is.object(props) && objHasProp(props, 'icon')) {
                cb(stringToHtmlElement(`<svg class="svg-icon"><use xlink:href="${props.icon}"></use></svg>`));
              }
            };

            loop(targets, target => {
              if (exist(target)) {
                callAll(target, select => {

                  if (is.undefined(select.choice)) {
                    select.choice = new Choices(select, {
                      searchEnabled: false,
                      classNames: variables.classNames.select,
                      callbackOnCreateTemplates() {
                        const
                          classNames = this.config.classNames,
                          itemSelectText = this.config.itemSelectText
                        ;

                        const {
                          item: itemClassName,
                          itemChoice,
                          itemDisabled,
                          itemSelectable,
                          highlightedState
                        } = classNames;

                        return  {
                          item: function(classNames, data) {
                            const item = createElement('div', {
                              class: `${toStr(itemClassName)} ${toStr(data.highlighted ? highlightedState : itemSelectable)}`,
                              dataItem: '',
                              dataId: toStr(data.id),
                              dataValue: toStr(data.value)
                            });

                            if (data.active) {
                              attr(item, 'aria-selected', 'true')
                            } else {
                              item.removeAttribute('aria-selected')
                            }

                            if (data.disabled) {
                              attr(item, 'aria-disabled', 'true')
                            } else {
                              item.removeAttribute('aria-disabled')
                            }

                            const name = html(createElement('span'), toStr(data.label));

                            item.appendChild(name);
                            appendIcon(data.customProperties, icon => item.appendChild(icon));

                            return item
                          },
                          choice: function(classNames, data) {

                            const choice = createElement('div', {
                              class: `${toStr(itemClassName)} ${toStr(itemChoice)} ${toStr(data.disabled ? itemDisabled : itemSelectable)}`,
                              dataSelectTest: toStr(itemSelectText),
                              dataChoice: '',
                              dataId: toStr(data.id),
                              dataValue: toStr(data.value)
                            });

                            if (data.disabled) {
                              attr(choice, 'data-choice-disabled', '');
                              attr(choice, 'aria-disabled', 'true')
                            } else {
                              attr(choice, 'data-choice-selectable', '')
                            }
                            attr(choice, 'role', data.groupId > 0 ? 'treeitem' : 'option');

                            const name = html(createElement('span'), toStr(data.label));
                            choice.appendChild(name);
                            appendIcon(data.customProperties, icon => choice.appendChild(icon));

                            return choice;
                          },
                        };


                      }
                    });

                    createdChoices.push(select);
                  }

                })
              }
            });

          }

          break;

        case 'dynamic':

          break;
      }
    },
    destroy = () => {
      loop(createdChoices, select => {
        select.choice.destroy();
        select.choice = undefined;
      });
      createdChoices = [];
    }
  ;


  return {create, destroy, createdChoices}
}