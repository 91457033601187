import Model from './Model';
import {$dom} from '../../helpers/dom';
import {$data} from '../../helpers/data';
import {$events} from '../../helpers/events';
import variables from '../../variables';
import is from 'is_js';
import {isElement, stringToHtmlElement, stringToHtmlElements} from '../../helpers/_utilities';
import {catalogFilterChangeObserver, searchBadgeDeleteObserver} from '../observers';
import {$style} from '../../helpers/style';

const {
  get: domGet,
  getAll: domGetAll,
  addClass,
  hasClass,
  removeClass,
  createElement,
  append,
  html,
  attr,
  exist,
  remove
} = $dom;

const {
  set: styleSet
} = $style;

export default class Search extends Model {
  constructor(options) {
    super(options);

    this.defaults = {
      showSimplePanelButtonSelector: '.js-search--button-open',
      blurClassName: 'header__search-blur',
      isOpenClassName: 'search-is-open',
      outputRootSelector: '.js-search-output',
      outputPlaceholderSelector: '.js-search-output--placeholder',
      outputListSelector: '.js-search-output--list',
      outputBadgeSelector: '.js-search-output--badge',
      outputBadgeDeleteButtonSelector: '.js-search-output--badge-delete',
      blurInsertAfterTarget: variables.dom.footer
    };

    this.options = $data.deepAssign(this.defaults, options);

    this.openSimplePanelButton = null;

    this.outputRoot = null;
    this.outputPlaceholder = null;
    this.outputList = null;

    this.togglePanel = this.togglePanel.bind(this);
    this.hidePanelByClickOutside = this.hidePanelByClickOutside.bind(this);

    this.onBadgeDelete = this.onBadgeDelete.bind(this);

    const $self = this;
    this.badges = {
      getCurrent(filterElId) {
        const s = `${$self.options.outputBadgeSelector}[${variables.dataAttributes.filterElId}="${filterElId}"]`;

        if (exist(s, $self.outputList)) return domGet(s, $self.outputList);

        return null;
      },
      selectors: {
        item: $self.options.outputBadgeSelector
      },
      printed: [],
      indexOf(item) {
        return this.printed.map(badge => badge.filter.elId).indexOf(item.filter.elId)
      },
      createMarkup(type, filterElId = null) {
        switch (type) {
          case 'listItem':

            return {
              listItem: createElement('li', {
                class: 'header__search-output-badge js-search-output--badge',
                dataFilterId: filterElId
              }),
              nameHolder: createElement('output', {
                class: 'header__search-output-badge-name text--small'
              }),
              deleteButton: createElement('button', {
                type: 'button',
                class: 'header__search-output-delete js-search-output--badge-delete'
              }),
              closeIcon: stringToHtmlElement(`<svg class="svg-icon"><use xlink:href=${$self.outputList.dataset.closeIconPath}></use></svg>`),
            }
        }
      },
      createListItem(filterElId, content) {

        const {
          listItem,
          nameHolder,
          deleteButton,
          closeIcon
        } = this.createMarkup('listItem', filterElId)

        append(listItem, nameHolder);
        append(listItem, deleteButton);
        append(deleteButton, closeIcon);
        html(nameHolder,content)

        return listItem
      },
      add(item) {
        this.printed.push(item);
        append($self.outputList, this.createListItem(item.filter.elId, item.content));
      },
      remove(item) {
        const
          indexOfItem = this.indexOf(item),
          printedObj = this.printed[indexOfItem]
        ;

        if (is.object(printedObj)) {
          remove(this.getCurrent(printedObj.filter.elId))
          this.printed.splice(this.indexOf(item), 1);
        }
      },
      printedElements() {
        if (exist(`${this.selectors.item}`, $self.outputList))
          return domGetAll(`${this.selectors.item}`, $self.outputList);
        return []
      },
      slice() {

        const needSlice = this.printed.length > $self.maxBadgesQuantity;

        styleSet($self.outputList, 'display',  this.printed.length > 0 ? '' : 'none');
        styleSet($self.outputPlaceholder, 'display', needSlice ? 'block' : 'none');

        const sliceQuantity = this.printed.length - $self.maxBadgesQuantity;
        const visibleBadges = [];

        const hiddenBadges = this.printedElements().filter((badge, index) => {
          if (index < sliceQuantity) {
            return badge
          } else {
            visibleBadges.push(badge);
            return false;
          }
        });

        styleSet(visibleBadges, 'display', 'block');
        styleSet(hiddenBadges, 'display', 'none');

      },
      print(data) {
        const {
          tabData,
          CatalogFilter
        } = data;

        const {items, type} = tabData;

        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          const indexOfItem = this.indexOf(item)

          if (item.involved) {
            if (indexOfItem === -1) {
              this.add(item);
            } else {
              const {rangeTypeName, lengthTypeName} = CatalogFilter;
              switch (type) {
                case rangeTypeName:
                case lengthTypeName:

                  if (this.printed[indexOfItem].content !== item.content) {
                    this.printed.splice(indexOfItem, 1);
                    this.printed.push(item);

                    html(domGet('output', this.getCurrent(item.filter.elId)), item.content)
                  }
                  break;
              }
            }
          } else {
            this.remove(item)
          }
        }

        this.slice();

      }
    }

    this.badges.print = this.badges.print.bind(this.badges)
    this.badges.add = this.badges.add.bind(this.badges)
    this.badges.createListItem = this.badges.createListItem.bind(this.badges)

    if (isElement(this.rootEl)) {
      this.viewType = this.rootEl.dataset.viewType;
      this.maxBadgesQuantity = +this.rootEl.dataset.maxBadges || 4;
    }
  }

  hidePanelByClickOutside(event) {
    const isClickInside = this.rootEl.contains(event.target);
    if (!isClickInside) this.togglePanel(null,'hide');
  }

  togglePanel(event, action = null) {

    if (is.null(action)) {
      action = hasClass(this.rootEl, variables.classNames.active) ? 'hide' : 'show';
    }

    const targets = [this.rootEl];

    if (this.viewType === 'simple') targets.push(this.openSimplePanelButton);

    let eventMethod, classListMethod;

    switch (action) {
      case 'show':
        classListMethod = 'addClass';
        eventMethod = 'on';

        addClass(document.body, this.options.isOpenClassName);
        break;
      case 'hide':
        classListMethod = 'removeClass';
        eventMethod = 'off';

        removeClass(document.body, this.options.isOpenClassName);
        break;
    }

    $dom[classListMethod](targets, variables.classNames.active);
    $events.delegate[eventMethod]('click tap', document, this.hidePanelByClickOutside);
  }

  setView(action) {
    switch (this.viewType) {
      case 'simple':
        switch (action) {
          case 'init':
            $events.delegate.on('click tap', this.options.showSimplePanelButtonSelector, this.togglePanel);
            break;
          case 'destroy':
            $events.delegate.off('click tap', this.options.showSimplePanelButtonSelector, this.togglePanel);
            break;
        }
        break;

      case 'extended':

        break
    }
  }

  onBadgeDelete(event) {
    const
      badge = event.target.closest(this.options.outputBadgeSelector),
      filterElId = attr(badge, variables.dataAttributes.filterElId),
      target = this.badges.printed.find(item => item.filter.elId === filterElId)
    ;

    if (is.object(target) && is.not.empty(target)) {
      this.badges.remove(target);
      searchBadgeDeleteObserver.dispatch(target);
    }
  }

  init() {

    if (this.viewType === 'simple') {
      this.openSimplePanelButton = domGet(this.options.showSimplePanelButtonSelector);
    }

    if (this.viewType === 'extended') {
      this.outputRoot = domGet(this.options.outputRootSelector);
      this.outputPlaceholder = domGet(this.options.outputPlaceholderSelector, this.outputRoot);
      this.outputList = domGet(this.options.outputListSelector, this.outputRoot);

      catalogFilterChangeObserver.subscribe(this.badges.print);

      $events.delegate.on('click tap', this.options.outputBadgeDeleteButtonSelector, this.onBadgeDelete)
    }

    this.setView('init');

    super.init(this);
  }
  destroy() {

    this.setView('destroy');
    this.togglePanel(null, 'hide');
    catalogFilterChangeObserver.unsubscribe(this.badges.print);
    $events.delegate.off('click tap', this.options.outputBadgeDeleteButtonSelector, this.onBadgeDelete);

    super.destroy(this);
  }
}