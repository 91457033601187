import Page from '../../сlasses/Page';
import setCatalogViewSizes from './viewSizes';
import CatalogFilter from './Filter';
import Player from '../../сlasses/Player';
import PlaybackExtractor from '../../сlasses/PlaybackExtractor';
import Router from '../../сlasses/Router';
import CatalogRenderer from './Renderer';
import variables from '../../../variables';
import is from 'is_js';
import toggleTrackCard from './toggleTrackCard';
import playerProgressSaver from '../utils/playerProgressSaver';
import {sleep, warn} from '../../../helpers/_utilities';

const
  catalogFilter = new CatalogFilter ({
    name: 'catalogFilter',
    rootElementId: 'js-catalog-filter'
  }),
  musicPlayer = new Player({
    name: 'musicPlayer',
    rootElementId: 'js-page-player'
  }),
  playbackExtractor = new PlaybackExtractor(),
  toggleTrackCards = toggleTrackCard()
;

let catalogRouter, catalogRenderer;

const catalogPage = new Page({
  onInit() {
    if (is.undefined(catalogRouter))
      catalogRouter = new Router({name: 'catalog'});

    if (is.undefined(catalogRenderer))

      catalogRenderer = new CatalogRenderer(
        variables.xhrRootUrl,
        'js-catalog-renderer-view'
      );

    this.catalogFilter = catalogFilter;
    this.musicPlayer = musicPlayer;
    this.router = catalogRouter;
    this.renderer = catalogRenderer;
    this.playbackExtractor = playbackExtractor;


    this.musicPlayer.progressSaver = playerProgressSaver;
    this.playbackExtractor.playerProgressSaver = playerProgressSaver;

    this.musicPlayer.init();
    this.playbackExtractor.init(catalogPage);

    window.musicPlayer = this.musicPlayer;

    this.renderer.init(catalogPage);
    this.router.init(catalogPage);
    this.catalogFilter.init(catalogPage);

window.musicPlayer = this.musicPlayer;
    toggleTrackCards.init();

    // .catch(e => {
    //   warn(e, 'catalogPage - onInit callback - async getData method');
    //   $style.set('#'+renderElementId, 'padding', '20px 10px');
    //   $dom.html('#'+renderElementId, '<h1>Whoops, looks like something went wrong!</h1>')
    // })

    // this.catalogFilter.toggleSettingsPanel('show')
    // this.catalogFilter.showTab('catalog-filter-id--length')
    // this.catalogFilter.showTab('catalog-filter-id--bpm')

  },
  onDestroy() {
    this.catalogFilter.destroy();
    this.musicPlayer.destroy();
    this.playbackExtractor.destroy();
    this.router.destroy();
    this.renderer.destroy();
    this.playbackExtractor.destroy();

    toggleTrackCards.destroy();
  },
  batch: false,
  loader: true,
  name: 'catalog',
  rootElementId: 'js-catalog-page',
  resizeDependentMethods: [
    setCatalogViewSizes
  ]
});

export default catalogPage;