import variables from '../../variables';
import {$dom} from '../../helpers/dom';
import {isElement} from '../../helpers/_utilities';
import {$style} from '../../helpers/style';
import is from 'is_js'

const checkTheme = swiper => {
  const {slides, el} = swiper;
  const attr = 'data-theme';
  const parent = el.closest('.js-home-intro--carousel-holder');

  $dom.each(slides, slide => {
    if ($dom.hasClass(slide, variables.classNames.active)) {
      const content = $dom.get(`[${attr}]`, slide);
      if (isElement(content)) {
        $dom.attr(parent, attr, $dom.attr(content, attr))
      }
    }
  })
};

const animateContent = swiper => {

  const {activeIndex, slides} = swiper;
  const activeSlide = slides[activeIndex];
  // const otherSlidesAnimations = Array.from(slides).map(slide => {
  //   if (slide !== activeSlide) {
  //     return $dom.getAll('.js-home-intro--animated', slide).map(target => {
  //       return {
  //         element: target,
  //         animationName: 'fadeOutRight',
  //         hideOnEnd: true
  //       }
  //     })
  //   }
  //   return false;
  // });
  //
  // $dom.callAll(otherSlidesAnimations, arr =>  $style.animateChain(arr));
  //
  // $style.animateChain($dom.callAll('.js-home-intro--animated', activeSlide).map(target => {
  //   return {
  //     element: target,
  //     animationName: 'fadeInLeft'
  //   }
  // }))
};

export default [
  {
    target: '.js-home-intro--carousel',
    apiOptions: {
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      allowTouchMove: false,
      pagination: {
        el: '.js-home-intro--carousel-pagination',
        type: 'bullets',
        clickable: true,
        bulletActiveClass: variables.classNames.active,
        bulletClass: 'home__intro-bullet',
        bulletElement: 'li'
      },
      autoplay: {
        delay: variables.carouselDefaultDuration * 8.5,
      },
      on: {
        init(swiper) {
          checkTheme(swiper);
          animateContent(swiper);
        },
        slideChangeTransitionStart(swiper) {
          checkTheme(swiper);
          animateContent(swiper);
        }
      }
    },
    customOptions: {
      lazy: true
    },
  }
]