export default class EventObserver {
  constructor() {
    this.observers = [];
  }

  subscribe(fn) {
    this.observers.push(fn)
  }

  unsubscribe(fn) {
    this.observers = this.observers.filter(subscriber => subscriber !== fn)
  }

  dispatch(data) {
    for (let i = 0; i < this.observers.length ; i++) {
      this.observers[i](data)
    }
  }
}